import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Modal,
  FloatingLabel,
  Form,
  Table,
  Badge,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from "axios";
import { authConfig } from "../../config";
import Loader from "../../components/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorClosed,
  faEye,
  faFileDownload,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import NotyfContext from "../../contexts/NotyfContext";
import Cookies from "js-cookie";
import Pagination from "../../components/Pagination";

const Support = () => {
  const [showUpdate, setShowUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tickets, setTickets] = useState([]);
  const notyf = useContext(NotyfContext);
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("des");
  const [page, setPage] = useState(1);
  const [url, setUrl] = useState("/api/v1/ticket/read");
  const limit = 10;
  const [totalPages, setTotalPages] = useState();
  const [ticket, setTicket] = useState({});
  const [updateLoader, setUpdateLoader] = useState("");
  const [loader, setLoader] = useState("");
  const [timerForMessage, setTimerForMessage] = useState(0);
  const [showChat, setShowChat] = useState(false);

  const onCloseChatModal = () => {
    setShowChat(false);
  };
  const onOpenChatModal = () => setShowChat(true);

  const [formData, setFormData] = useState({
    subject: "",
    order: "",
    message: "",
  });
  const [edit, setEdit] = useState(false);
  const onCloseModal = () => {
    setShowUpdate(false);
  };
  const onOpenModal = () => setShowUpdate(true);

  useEffect(() => {
    getTickets();
  }, [page]);

  const getTickets = async (search) => {
    setLoading(<Loader />);
    var link = "";

    if (search) {
      link =
        url +
        "?page=" +
        page +
        "&limit=" +
        10 +
        "&sort=" +
        sort +
        "&status=" +
        filter +
        "&search=" +
        search;
    } else {
      link =
        url +
        "?page=" +
        page +
        "&limit=" +
        10 +
        "&sort=" +
        sort +
        "&status=" +
        filter;
    }
    await axios
      .get(authConfig.server + link, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setTickets(res.data.tickets);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(""));
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(<Loader />);
    let data = {
      order: formData.order,
      message: formData.message,
      subject: formData.subject,
    };

    await axios
      .post(authConfig.server + "/api/v1/ticket/create", data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        getTickets();
        notyf.open({
          type: "success",
          message: res?.data?.message,
        });
      })
      .catch((err) => {
        console.log(err);
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
      })
      .finally(() => setLoading(""));

    setTimeout(() => {
      data = {};
      setLoading("");
      e.target.reset();
    }, 3000);
  };

  const updateTicket = async (id) => {
    setUpdateLoader(<Loader />);
    const data = {
      ticketId: id,
      status: "closed",
    };

    await axios
      .put(authConfig.server + "/api/v1/ticket/update/", data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setUpdateLoader("");
        getTickets();
        notyf.open({
          type: "success",
          message: res?.data?.message,
        });
      })
      .catch((err) => {
        setUpdateLoader("");
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
      });
    setTimeout(() => {
      setUpdateLoader("");
    }, 3000);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    setUpdateLoader(<Loader />);
    if (e.target.message.value === "") {
      notyf.open({
        type: "danger",
        message: "Can't send Empty Message",
      });
    }
    const data = {
      ticketId: ticket._id,
      message: e.target.message.value,
    };

    await axios
      .put(authConfig.server + "/api/v1/ticket/message", data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setUpdateLoader("");
        notyf.open({
          type: "success",
          message: "Message sent successfully",
        });
        setTicket(res.data.ticket);
        setTimerForMessage(30000);
      })
      .catch((err) => {
        console.log(err);
        setUpdateLoader("");
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
      });

    setTimeout(() => {
      e.target.reset();
    }, 3000);
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="nk-block-title page-title text-info">Support</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={4}>
          <Card>
            <Card.Body>
              <Row className="mb-2">
                <Col lg={12} className="align-left mb-2">
                  <h5>Create Ticket</h5>
                </Col>
                <Col lg={12} className="align-left">
                  {loading}
                  <form onSubmit={onSubmit}>
                    <FloatingLabel label="Enter Title *" className="mb-3">
                      <Form.Control
                        type="text"
                        label="Title *"
                        name="subject"
                        placeholder="Enter Title *"
                        onChange={(e) =>
                          setFormData({ ...formData, subject: e.target.value })
                        }
                        containerClass={"mb-3"}
                        key="subject"
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Enter Order ID *" className="mb-3">
                      <Form.Control
                        type="text"
                        label="Order ID *"
                        name="order"
                        placeholder="Enter Order ID *"
                        onChange={(e) =>
                          setFormData({ ...formData, order: e.target.value })
                        }
                        containerClass={"mb-3"}
                        key="order"
                      />
                    </FloatingLabel>
                    <FloatingLabel label="Enter Message *" className="mb-3">
                      <Form.Control
                        type="textarea"
                        rows="5"
                        label="Message *"
                        name="message"
                        placeholder="Enter Message *"
                        onChange={(e) =>
                          setFormData({ ...formData, message: e.target.value })
                        }
                        containerClass={"mb-3"}
                        key="message"
                      />
                    </FloatingLabel>

                    <Row>
                      <Col xs={4}></Col>
                      <Col xs={8} className="text-end">
                        <Button
                          variant="success"
                          type="submit"
                          className="btn btn-success "
                          size="md"
                        >
                          Create
                        </Button>
                      </Col>
                    </Row>
                  </form>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>

        <Col lg={8}>
          <Card>
            <Card.Body>
              <Row className="mb-2">
                <Col lg={8}>
                  <Row className="gy-2 gx-2 align-items-center justify-content-xl-start justify-content-between">
                    <Col xs="auto">
                      <Form.Control
                        type="text"
                        name="search"
                        width="100%"
                        placeholder="Search by Title, Status..."
                        onChange={(e) => {
                          getTickets(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <div className="text-xl-end mt-xl-0 mt-2"></div>
                </Col>
              </Row>

              <div className="table-responsive" style={{ overflowY: "hidden" }}>
                <Table className="mb-0 mt-3 " striped>
                  <thead className="table-dark">
                    <tr>
                      <th>Ticket ID</th>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Last Response</th>
                      <th>CreatedAt</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading}
                    {tickets?.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{record._id}</td>
                          <td>{record.subject}</td>
                          <td>
                            <h5>
                              {record.status === "open" ? (
                                <Badge bg="success" pill>
                                  <small>{record.status}</small>
                                </Badge>
                              ) : record.status === "closed" ? (
                                <Badge bg="danger" pill>
                                  <small>{record.status}</small>
                                </Badge>
                              ) : (
                                <Badge bg="warning" pill>
                                  <small>{record.status}</small>
                                </Badge>
                              )}
                            </h5>
                          </td>
                          <td>
                            <span>
                              {new Date(record.updatedAt).toLocaleDateString()}
                              <small className="text-muted m-1">
                                {new Date(
                                  record.updatedAt,
                                ).toLocaleTimeString()}
                              </small>
                            </span>
                          </td>
                          <td>
                            <span>
                              {new Date(record.createdAt).toLocaleDateString()}
                            </span>
                          </td>

                          <td>
                            <div className="d-flex gap-2 text-center align-center">
                              {record.status === "open" ||
                              record.status ===
                                "waiting for customer response" ? (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip>Close Ticket</Tooltip>}
                                >
                                  <Button
                                    variant="danger"
                                    className="btn btn-sm"
                                    size="small"
                                    onClick={() => {
                                      setTicket(record);

                                      updateTicket(record._id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faDoorClosed} />
                                  </Button>
                                </OverlayTrigger>
                              ) : (
                                ""
                              )}

                              <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip>View</Tooltip>}
                              >
                                <Button
                                  variant="info"
                                  className="btn btn-sm"
                                  size="small"
                                  onClick={() => {
                                    setTicket(record);
                                    onOpenChatModal();
                                  }}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                </Button>
                              </OverlayTrigger>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
              {/* <Table
                columns={columns}
                data={tickets}
                pageSize={10}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
                // isSelectable={true}
                // isSearchable={true}
                theadClass="table-light"
                searchBoxClass="mb-2"
              /> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={showChat}
        onHide={onCloseChatModal}
        backdrop="static"
        keyboard={false}
        size={"lg"}
        centered
      >
        {/* messages */}
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3 className="text-primary">
              {"Ticket Messages - " + ticket?.subject}{" "}
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="px-3" style={{ maxHeight: "350px", width: "100%" }}>
            <div className="card border mb-3" style={{}}>
              <div className="card-body">
                {/* {updateLoader} */}
                {ticket?.messages?.map((message) => (
                  <div key={message._id}>
                    <div
                      className={
                        "row " +
                        (message.username !== "admin" &&
                          "justify-content-end text-end")
                      }
                    >
                      <div
                        className="col-auto"
                        style={{
                          maxWidth: "80%",
                        }}
                      >
                        <div>
                          <p
                            className="p-0 m-0 text-danger"
                            style={{
                              fontSize: "18px",
                            }}
                          >
                            {message.username}
                          </p>
                        </div>
                        <div
                          className={`card border bg-${
                            message.username === "admin" ? "light" : "primary"
                          } mb-2`}
                        >
                          <div className="card-body pb-1 px-2 pt-1 text-dark">
                            {message.message}
                            <p
                              className="p-0 m-0 text-gray"
                              style={{
                                fontSize: "8px",
                              }}
                            >
                              {new Date(message.timestamp).toLocaleString()}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="card-footer">
            <form onSubmit={sendMessage}>
              <div className="d-flex gap-2">
                <div className="form-group  w-100">
                  <input
                    className="form-control"
                    placeholder="Type your message here"
                    name="message"
                    required
                  />
                </div>

                <div className="d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    type="submit"
                    disabled={ticket?.status === "closed"}
                  >
                    Send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Support;
