import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Card, Row } from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie";
import { DollarSign, MessageSquare, ShoppingBag } from "react-feather";
import Loader from "../../components/Loader";
import { authConfig } from "../../config";

const Statistics = (dasboard) => {
  return (
    <Row>
      <Col md="6" xl="6" className="d-flex">
        <Card className="flex-fill bg-info">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">
                  {"$" +
                    " " +
                    (Number(dasboard.dasboard?.balance).toFixed(2) ?? "0.00")}
                </h3>
                <h4 className="mb-2">Balance</h4>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <DollarSign className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      {/* spent */}
      <Col md="6" xl="6" className="d-flex">
        <Card className="flex-fill bg-warning">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">
                  {" "}
                  {"$" + " " + (dasboard.dasboard?.totalSpent ?? "0.00")}
                </h3>
                <h4 className="mb-2">Total Spent</h4>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <DollarSign className="align-middle text-info" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      {/* orders */}
      <Col md="6" xl="6" className="d-flex">
        <Card className="flex-fill bg-success">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{dasboard.dasboard?.orders ?? "0"}</h3>
                <h4 className="mb-2"> Orders</h4>
              </div>
              <div className="d-inline-block ms-3 text-success">
                <div className="stat text-success">
                  <ShoppingBag className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md="6" xl="6" className="d-flex">
        <Card className="flex-fill bg-danger">
          <Card.Body className=" py-4">
            <div className="d-flex align-items-start">
              <div className="flex-grow-1">
                <h3 className="mb-2">{dasboard.dasboard?.tickets ?? "0"}</h3>
                <h4 className="mb-2">Tickets</h4>
              </div>
              <div className="d-inline-block ms-3">
                <div className="stat">
                  <MessageSquare className="align-middle text-success" />
                </div>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default Statistics;
