import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import { useRef } from "react";
import axios from "axios";
import { authConfig } from "../../config";
import ReCAPTCHA from "react-google-recaptcha";
import NotyfContext from "../../contexts/NotyfContext";
import HCaptcha from "@hcaptcha/react-hcaptcha";
function SignUp() {
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const notyf = useContext(NotyfContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [token, setToken] = useState(null);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          username: "",
          confirmPassword: "",
          submit: false,
          referralToken: "",
        }}
        validationSchema={Yup.object().shape({
          username: Yup.string().max(255).required("Email is required"),
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string()
            .min(8, "Must be at least 8 characters")
            .max(255)
            .required("Required"),
          confirmPassword: Yup.string()
            .oneOf([Yup.ref("password")])
            .required("Please Re-Enter Password"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // recaptchaRef.current.execute();
          const data = {
            email: values.email,
            password: values.password,
            username: values.username,
            // captchaToken: token,
            referralToken: values.referralToken,
          };

          await axios
            .post(authConfig.server + "/api/v1/auth/register", data)
            .then((res) => {
              notyf.open({
                type: "success",
                message: "Account Created Successfully Please Verify",
              });
              localStorage.setItem("email", data.email);
              setTimeout(() => {
                navigate("/auth/verify");
              }, 1000);
            })
            .catch((error) => {
              recaptchaRef.current.resetCaptcha();
              notyf.open({
                type: "danger",
                message: error.response?.data?.message,
              });
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                name="username"
                placeholder="Username"
                value={values.username}
                isInvalid={Boolean(touched.username && errors.username)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.username && (
                <Form.Control.Feedback type="invalid">
                  {errors.username}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Email address"
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Referral Token (Optional)</Form.Label>
              <Form.Control
                type="text"
                name="referralToken"
                placeholder="Enter Referral Token"
                value={values.referralToken}
                isInvalid={Boolean(
                  touched.referralToken && errors.referralToken,
                )}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                name="password"
                placeholder="Password"
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Confirm Password</Form.Label>
              <Form.Control
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={values.confirmPassword}
                isInvalid={Boolean(
                  touched.confirmPassword && errors.confirmPassword,
                )}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.confirmPassword && (
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            {/* <div className="text-center ">
              <HCaptcha
                sitekey={authConfig.RECAPTCHA_KEY}
                onVerify={setToken}
                ref={recaptchaRef}
                data-theme="dark"
                data-size="invisible"
              />
            </div> */}
            <div className="text-center ">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                Sign up
              </Button>
            </div>

            <div className="text-center mt-3">
              Already Have Account?
              <span style={{ marginLeft: "5px" }}>
                <Link to="/auth/sign-in">Log In</Link>
              </span>
            </div>
          </Form>
        )}
      </Formik>
      {/* <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={authConfig.RECAPTCHA_KEY}
        size="invisible"
      /> */}
    </>
  );
}

export default SignUp;
