import {
  Badge,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { authConfig } from "../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faMoneyCheck,
  faUser,
  faWallet,
} from "@fortawesome/free-solid-svg-icons";
import Pagination from "../../components/Pagination";
import NotyfContext from "../../contexts/NotyfContext";

const Referrals = () => {
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingStats, setLoadingStats] = useState(false);
  const [comissions, setComissions] = useState([]);
  const [commisionsLoading, setCommisionsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const notyf = useContext(NotyfContext);
  const data = localStorage.getItem("userdata");
  const refToken = JSON.parse(data);

  const readStats = async () => {
    setLoadingStats(true);
    await axios
      .get(authConfig.server + "/api/v1/referral/readStats", {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setStats(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadingStats(false));
  };

  const readComissions = async () => {
    setCommisionsLoading(true);
    await axios
      .get(
        `${authConfig.server}/api/v1/referral/readReferralOrders?page=${page}&limit=10`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      )
      .then((res) => {
        setComissions(res.data.referralOrders);
        setTotalPages(res.data.pages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setCommisionsLoading(false));
  };

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);

    notyf.open({
      type: "success",
      message: "Copied to clipboard!",
    });
  };

  useEffect(() => {
    readStats();
    readReferrals();
    readComissions();
  }, []);

  const readReferrals = async () => {
    setLoading(true);
    await axios
      .get(authConfig.server + "/api/v1/referral/read", {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setUsers(res.data.referrals);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    readStats();
    readReferrals();
  }, []);

  const REF_LINK = refToken.referralToken;

  return (
    <>
      <Form.Group className="mb-4 d-flex gap-2 w-100">
        <InputGroup>
          <InputGroup.Text className="text-info">
            Your Referral Token
          </InputGroup.Text>
          <FormControl
            size="lg"
            className="text-info"
            type="text"
            name="apiKey"
            value={REF_LINK}
          />

          <InputGroup.Text
            className="text-info"
            onClick={() => copyToClipboard(REF_LINK)}
          >
            <FontAwesomeIcon icon={faCopy} />
          </InputGroup.Text>
        </InputGroup>
      </Form.Group>

      <Row className="row-cols-1 row-cols-lg-3 g-3">
        <Col md="4" xl="4" className="d-flex">
          <Card className="flex-fill bg-info">
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2 text-white">Referral Earning %</h3>
                  <div className="mb-0">
                    <h2>
                      <Badge bg="" className="badge badge-soft-warning me-2">
                        5%
                      </Badge>
                      <span className="text-muted h5 ">
                        of Sales via Your Referred User
                      </span>
                    </h2>
                  </div>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat text-center">
                    <FontAwesomeIcon icon={faWallet} className="h2" />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4" xl="4" className="d-flex">
          <Card className="flex-fill bg-success">
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2 text-white">Total Referred</h3>
                  <div className="mb-0">
                    <h2 className="text-white">{stats.referrals ?? "0"}</h2>
                  </div>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat text-center">
                    <FontAwesomeIcon icon={faUser} className="h2" />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col md="4" xl="4" className="d-flex">
          <Card className="flex-fill bg-warning">
            <Card.Body className=" py-4">
              <div className="d-flex align-items-start">
                <div className="flex-grow-1">
                  <h3 className="mb-2 text-white">Total Referral Points</h3>
                  <div className="mb-0">
                    <h2 className="text-white">
                      {stats.refPoints?.toFixed(2) ?? "0"}
                    </h2>
                  </div>
                </div>
                <div className="d-inline-block ms-3">
                  <div className="stat  text-center">
                    <FontAwesomeIcon icon={faMoneyCheck} className="h3" />
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="g-4">
        <Col lg={6}>
          <div className="table-responsive" style={{ overflowY: "hidden" }}>
            <Table className="mb-0 mt-3 " striped>
              <thead className="table-dark">
                <tr>
                  <th>Sr.No.</th>
                  <th>Username</th>
                  <th>Types</th>
                  <th>Points</th>
                </tr>
              </thead>
              <tbody>
                {comissions.map((order, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{order.user?.username}</td>
                    <td>{order.order?.labelType?.name}</td>
                    <td>{order.points?.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Pagination page={page} setPage={setPage} totalPages={totalPages} />
        </Col>
        <Col lg={6}>
          <div className="table-responsive" style={{ overflowY: "hidden" }}>
            <Table className="mb-0 mt-3 " striped>
              <thead className="table-dark">
                <tr>
                  <th>#</th>
                  <th>Username</th>
                  <th>Email</th>
                  <th>Joined</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{user.username}</td>
                    <td>{user.email}</td>
                    <td>{new Date(user.createdAt).toDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Referrals;
