import {
  Sliders,
  PlusCircle,
  Book,
  List,
  HelpCircle,
  ShoppingCart,
  BookOpen,
  Package,
  Aperture,
  Users,
  Info,
  ShoppingBag,
} from "react-feather";

const pagesSection = [
  {
    icon: Sliders,
    title: "Dashboards",
    href: "/",
  },
  {
    icon: Book,
    title: "Address",
    href: "/address",
  },
  {
    icon: ShoppingCart,
    href: "/create-label",
    title: "Create Label",
  },
  {
    icon: ShoppingBag,
    href: "/create-int-label",
    title: "Create International Label",
  },
  {
    icon: Book,
    href: "/int-order-history",
    title: "International Orders ",
  },
  {
    icon: Package,
    href: "/bulk-orders",
    title: "Bulk Orders",
  },
  {
    icon: BookOpen,
    href: "/order-history",
    title: "Orders History",
  },
  // {
  //   icon: List,
  //   title: "Orders",
  //   href: "/orders",
  //   children: [
  //     {
  //       icon: ShoppingCart,
  //       href: "/create-label",
  //       title: "Create Label",
  //     },
  //     {
  //       icon: Package,
  //       href: "/bulk-orders",
  //       title: "Bulk Orders",
  //     },
  //     {
  //       icon: BookOpen,
  //       href: "/order-history",
  //       title: "Orders History",
  //     },
  //   ],
  // },
  {
    icon: PlusCircle,
    title: "Deposit Balance",
    href: "/depositBalance",
  },
  {
    icon: HelpCircle,
    title: "Support",
    href: "/support",
  },
  {
    icon: Aperture,
    title: "API",
    href: "/api",
  },
  {
    icon: Users,
    title: "Referrals",
    href: "/referrals",
  },
  {
    icon: Info,
    href: "/faqs",
    title: "FAQs",
  },
  // {
  //   href: "/pages",
  //   icon: Layout,
  //   title: "Pages",
  //   children: [
  //     {
  //       href: "/pages/profile",
  //       title: "Profile",
  //     },
  //     {
  //       href: "/pages/settings",
  //       title: "Settings",
  //     },
  //     {
  //       href: "/pages/clients",
  //       title: "Clients",
  //     },
  //     {
  //       href: "/pages/projects",
  //       title: "Projects",
  //     },
  //     {
  //       href: "/pages/invoice",
  //       title: "Invoice",
  //     },
  //     {
  //       href: "/pages/pricing",
  //       title: "Pricing",
  //     },
  //     {
  //       href: "/pages/tasks",
  //       title: "Tasks",
  //     },
  //     {
  //       href: "/pages/chat",
  //       title: "Chat",
  //       badge: "New",
  //     },
  //     {
  //       href: "/pages/blank",
  //       title: "Blank Page",
  //     },
  //   ],
  // },
  // {
  //   href: "/auth",
  //   icon: Users,
  //   title: "Auth",
  //   children: [
  //     {
  //       href: "/auth/sign-in",
  //       title: "Sign In",
  //     },
  //     {
  //       href: "/auth/sign-up",
  //       title: "Sign Up",
  //     },
  //     {
  //       href: "/auth/reset-password",
  //       title: "Reset Password",
  //     },
  //     {
  //       href: "/auth/404",
  //       title: "404 Page",
  //     },
  //     {
  //       href: "/auth/500",
  //       title: "500 Page",
  //     },
  //   ],
  // },
];

const navItems = [
  {
    title: "Pages",
    pages: pagesSection,
  },
];

export default navItems;
