import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";
import { authConfig } from "../../config";

const Faqs = () => {
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);

  const readFaq = async () => {
    setLoading(true);
    await axios
      .get(authConfig.server + "/api/v1/faq/read", authConfig.config)
      .then((res) => {
        setFaqs(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    readFaq();
  }, []);
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h3 className="page-title text-info">FAQs</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Accordion
            defaultActiveKey="0"
            id="accordion"
            className="mb-3 accordion-collapse collapse show"
          >
            {faqs.map((faq, index) => (
              <Accordion.Item
                eventKey={index}
                className="faq-item mb-3 border rounded"
              >
                <Accordion.Header>
                  <h4 className="title text-info">{index + 1} </h4>

                  <h4 className="title text-info">: {faq.question}</h4>
                </Accordion.Header>
                <Accordion.Body
                  collapse
                  className="text-primary accordion-collapse collaps"
                >
                  {faq.answer}
                </Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </>
  );
};

export default Faqs;
