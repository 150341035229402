import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import useSidebar from "../../hooks/useSidebar";
import SidebarNav from "./SidebarNav";
import { ReactComponent as Logo } from "../../assets/img/logo.svg";
import SidebarFooter from "./SidebarFooter";

const Sidebar = ({ items, showFooter = true }) => {
  const { isOpen } = useSidebar();

  return (
    <nav className={`sidebar ${!isOpen ? "collapsed" : ""} `}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <Logo /> <span className="align-middle me-3">WolfShip</span>
          </a>
          <div className="mb-7">
            <SidebarNav items={items} />
          </div>

          <div className="float-end mt-6 mb-0">
            <SidebarFooter />
          </div>
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
