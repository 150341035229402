import React from "react";

import { Badge, Card, Dropdown, Table } from "react-bootstrap";

import { MoreHorizontal } from "react-feather";

export const RecentOrders = ({ loading, dasboard }) => (
  <Card className="flex-fill w-100">
    <Card.Header>
      <Card.Title className="mb-0">RECENT ORDERS</Card.Title>
    </Card.Header>
    <div className="table-responsive" style={{ overflowY: "hidden" }}>
      <Table striped className="my-0">
        <thead className="table-dark">
          <tr>
            <th>Type</th>
            <th>From</th>
            <th>To</th>
            <th>Price</th>
          </tr>
        </thead>
        <tbody>
          {loading}
          {dasboard?.latestOrders?.map((record, index) => {
            return (
              <tr key={index}>
                <td>{record.labelType?.name}</td>
                <td>{record.FromName}</td>
                <td>{record.ToName}</td>
                <td>${record.price}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  </Card>
);

export const RecentDeposit = ({ loading, dasboard }) => (
  <Card className="flex-fill w-100">
    <Card.Header>
      <Card.Title className="mb-0">RECENT TRANSACTIONS</Card.Title>
    </Card.Header>
    <div className="table-responsive" style={{ overflowY: "hidden" }}>
      <Table striped className="my-0">
        <thead className="table-dark">
          <tr>
            <th>Date</th>
            <th>Amount</th>
            <th>PAYMENT METHOD</th>
            <th>STATUS</th>
          </tr>
        </thead>
        <tbody>
          {/* {loading} */}
          {dasboard?.latestInvoices?.map((record, index) => {
            return (
              <tr key={index}>
                <td>{new Date(record.updatedAt).toLocaleDateString()}</td>
                <td>${record.amount}</td>
                <td>{record.payment_method}</td>
                <td>
                  {" "}
                  {record.status === "paid" ? (
                    <Badge bg="success"> {record.status}</Badge>
                  ) : record.status === "cancelled" ? (
                    <Badge bg="danger"> {record.status}</Badge>
                  ) : (
                    <Badge bg="warning"> {record.status}</Badge>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  </Card>
);
