import React, { useEffect, useState } from "react";
import { useNavigate, useRoutes } from "react-router-dom";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { store } from "./redux/store";

import "./i18n";
import routes from "./routes";

import { ThemeProvider } from "./contexts/ThemeContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import { LayoutProvider } from "./contexts/LayoutContext";
// import ChartJsDefaults from "./utils/ChartJsDefaults";
import { AuthProvider } from "./contexts/JWTContext";
import { authConfig } from "./config";
import axios from "axios";
import Loader from "./components/Loader";
import Cookies from "js-cookie";

const App = () => {
  const content = useRoutes(routes);
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    checkUserLogin();
  }, []);

  const checkUserLogin = async () => {
    await axios(authConfig.server + "/api/v1/auth/access", {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((res) => {
        localStorage.setItem("userdata", JSON.stringify(res.data));
        setIsLogin(true);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLogin(false);
        setIsLoaded(true);
        navigate("/auth/sign-in");
        localStorage.removeItem("userdata");
      });
  };

  const data = localStorage.getItem("userdata");

  if (!isLogin && !isLoaded) {
    return <Loader />;
  }

  return (
    <HelmetProvider>
      <Helmet />
      <Provider store={store}>
        <ThemeProvider>
          <SidebarProvider>
            <LayoutProvider>
              {/* <ChartJsDefaults /> */}
              <AuthProvider>{content}</AuthProvider>
            </LayoutProvider>
          </SidebarProvider>
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
