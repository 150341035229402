import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Container, Row, Col, Card } from "react-bootstrap";
import Statistics from "./Statistics";
import Feed from "./Feed";
import { RecentDeposit, RecentOrders } from "./Projects";
import { authConfig } from "../../config";
import Cookies from "js-cookie";
import Loader from "../../components/Loader";
import axios from "axios";
import { DollarSign } from "react-feather";

const Default = () => {
  const [loading, setLoading] = useState("");
  const [dasboard, setDasboard] = useState({});
  const [announcement, setAnnouncement] = useState({});

  useEffect(() => {
    readDashboard();
    readAnnouncement();
  }, []);

  const readDashboard = async () => {
    setLoading(<Loader />);
    await axios

      .get(authConfig.server + "/api/v1/auth/readStats", {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setDasboard(res.data);
        setLoading("");
      })
      .catch((err) => {
        console.log(err);
        setLoading("");
      });
  };

  const readAnnouncement = async () => {
    setLoading(<Loader />);
    await axios

      .get(authConfig.server + "/api/v1/announcement/read", {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setAnnouncement(res.data);
        setLoading("");
      })
      .catch((err) => {
        console.log(err);
        setLoading("");
      });
  };

  return (
    <React.Fragment>
      <Helmet title="Dashboard" />
      <Container fluid className="p-0">
        <Row className="mb-2 mb-xl-3">
          <Col xs="auto" className="">
            <h3 className="text-info">Dashboard</h3>
          </Col>
        </Row>

        {announcement?.status && (
          <Row>
            <Col xs="auto" className=" w-100">
              <Card className="flex-fill bg-info">
                <Card.Body className=" py-4">
                  <div className="d-flex align-items-start">
                    <div className="flex-grow-1">
                      <h3 className="mb-2">{announcement.title}</h3>
                      <h4 className="mb-2">{announcement.announcement}</h4>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <Row>
          <Col lg="6" xl="6" className="d-flex">
            <Feed />
          </Col>
          <Col lg="6" xl="6" className="d-flex">
            <Statistics dasboard={dasboard} />
          </Col>
        </Row>
        <Row>
          <Col lg="6" xl="6" className="d-flex">
            <RecentOrders dasboard={dasboard} loading={loading} />
          </Col>
          <Col lg="6" xl="6" className="d-flex">
            <RecentDeposit dasboard={dasboard} loading={loading} />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default Default;
