const Cookies = require("js-cookie");
var hostname = window.location.href;

export const authConfig = {
  server: hostname.includes("localhost")
    ? "http://localhost:7400"
    : "https://api.goship.gg",

  config: {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  },

  downloadConfig: {
    responseType: "blob",
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  },

  // recaptcha keys
  // RECAPTCHA_KEY: "4c8ba1ee-0002-4a17-b8f7-fa4da1ede24f",

  // recaptcha keys test
  RECAPTCHA_KEY: "30000000-ffff-ffff-ffff-000000000003",

  // "10000000-ffff-ffff-ffff-000000000001"

  checkAccess: (err) => {
    if (err.response) {
      if (err.response.status === 401) {
        Cookies.remove("token");
        window.location.href = "/";
      }
    }
    return true;
  },
};
