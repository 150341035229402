import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import { useRef } from "react";
import { useContext } from "react";
import NotyfContext from "../../contexts/NotyfContext";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { authConfig } from "../../config";
import axios from "axios";

function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const recaptchaRef = useRef(null);
  const notyf = useContext(NotyfContext);
  const [token, setToken] = useState(null);

  return (
    <Formik
      initialValues={{
        email: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        // recaptchaRef.current.execute();

        const data = {
          email: values.email,
          // captchaToken: token,
        };

        await axios
          .post(authConfig.server + "/api/v1/auth/forgotPassword", data)
          .then((res) => {
            notyf.open({
              type: "success",
              message: res?.data?.message,
            });
            localStorage.setItem("email", data.email);
            setTimeout(() => {
              navigate("/auth/confirm");
            }, 3000);
          })
          .catch((err) => {
            const message = err.message || "Something went wrong";
            notyf.open({
              type: "danger",
              message: err.response?.data?.message,
            });
            recaptchaRef.current.resetCaptcha();
            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          });
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert className="my-3" variant="danger">
              <div className="alert-message">{errors.submit}</div>
            </Alert>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              label="Email Address"
              value={values.email}
              isInvalid={Boolean(touched.email && errors.email)}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {!!touched.email && (
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          {/* <div className="text-center ">
            <HCaptcha
              sitekey={authConfig.RECAPTCHA_KEY}
              onVerify={setToken}
              ref={recaptchaRef}
              data-theme="dark"
              data-size="invisible"
            />
          </div> */}
          <div className="text-center ">
            <Button
              type="submit"
              variant="primary"
              size="lg"
              disabled={isSubmitting}
            >
              Reset password
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default ResetPassword;
