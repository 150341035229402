import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Badge,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Stack,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";

const createOrderReqDetails = [
  { type: "String", name: "type", desc: "uid (from given labels list)" },
  { type: "Float", name: "weight", desc: "Pacakge weight" },
  { type: "Float", name: "length", desc: "Pacakge length (UPS orders)" },
  { type: "Float", name: "width", desc: "Pacakge width (UPS orders)" },
  { type: "Float", name: "height", desc: "Pacakge height (UPS orders)" },
  {
    type: "String",
    name: "FromCountry",
    desc: "Sender country (Two-Letter Country Code)",
  },
  { type: "String", name: "FromName", desc: "Sender name" },
  { type: "String", name: "FromCompany", desc: "Sender company (optional)" },
  { type: "String", name: "FromStreet", desc: "Sender street address" },
  {
    type: "String",
    name: "FromState",
    desc: "Sender state (two letter state code)",
  },
  { type: "String", name: "FromZip", desc: "Sender ZIP code" },
  {
    type: "String",
    name: "ToCountry",
    desc: "Receiver country (Two-Letter Country Code)",
  },
  { type: "String", name: "ToName", desc: "Receiver name" },
  { type: "String", name: "ToCompany", desc: "Receiver company (optional)" },
  { type: "String", name: "ToStreet", desc: "Receiver street address" },
  {
    type: "String",
    name: "ToStreet2",
    desc: "Receiver secondary address (optional)",
  },
  { type: "String", name: "ToCity", desc: "Receiver city" },
  {
    type: "String",
    name: "ToState",
    desc: "Receiver state (two letter state code)",
  },
  { type: "String", name: "ToZip", desc: "Receiver ZIP code" },
  {
    type: "String",
    name: "reference1",
    desc: "reference fror order (optional)",
  },
  {
    type: "String",
    name: "reference2",
    desc: "reference fror order (optional)",
  },
];

const CreateOrder = () => {
  const [key, setKey] = useState("Success");
  return (
    <Card>
      <Card.Body>
        <h3 className=" text-info mb-2">Create Order</h3>
        <div className=" mb-3">
          <h4 className="d-flex gap-3 ">
            <Badge bg="primary">POST</Badge>
            <Badge bg="secondary" className="mr-4">
              https://api.goship.gg/api/v2/order/createOrder
            </Badge>
          </h4>
          <h4 className="d-flex gap-2 ">
            <b>Headers:</b>
            <Badge bg="warning">x-api-key : "API KEY"</Badge>
          </h4>
        </div>
        <div className="mb-3">
          <h5>Request</h5>
          <div class="table-responsive">
            <Table>
              <thead className="table-dark">
                <tr>
                  <th>Type</th>
                  <th>Parameter</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {createOrderReqDetails.map((parameter) => (
                  <tr>
                    <td>{parameter.type}</td>
                    <td>{parameter.name}</td>
                    <td>{parameter.desc}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <h5>Response</h5>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="Success" title="Success">
            <textarea
              disabled
              style={{ height: "20rem", fontFamily: "monospace" }}
              className="w-100 text-warning bg-dark rounded-2 p-3 mt-3"
              value={`{
    "message": "Order created successfully",
    "order": <Order Object>
}`}
            />
          </Tab>
          <Tab eventKey="Failure" title="Failure">
            <textarea
              disabled
              style={{ height: "20rem", fontFamily: "monospace" }}
              className="w-100 text-danger bg-dark rounded-2 p-3 mt-3"
              value={`
                {
    "status": 400 || 401 || 404,
    "message": ""
}
                    
                    `}
            />
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default CreateOrder;
