import React from "react";
import "./footerButton.css";
const SidebarFooter = () => {
  return (
    <div className="sidebar-cta">
      <a href="https://t.me/banksyship" target="_blank" rel="noreferrer">
        <button className="discord-btn telegram-btn text-black">
          <img src="images/Telegram-1.svg" alt="icon" /> Our Telegram
          <img src="images/Frame 1.svg" alt="icon" />
        </button>
      </a>
    </div>
  );
};

export default SidebarFooter;
