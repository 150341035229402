import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Card, Form } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import Cookies from "js-cookie";
import { authConfig } from "../../config";
import axios from "axios";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Helmet } from "react-helmet-async";
import NotyfContext from "../../contexts/NotyfContext";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useState } from "react";
import { useEffect } from "react";
function ResetPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const recaptchaRef = useRef(null);
  const notyf = useContext(NotyfContext);
  const [token, setToken] = useState(null);

  const checkUserLogin = async () => {
    await axios(authConfig.server + "/api/v1/auth/access", {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((res) => {
        localStorage.setItem("userdata", JSON.stringify(res.data));
      })
      .catch((err) => {});
  };

  return (
    <>
      <Helmet title="Email Verification" />
      <div className="text-center mt-4">
        <h1 className="h2">Email Verification</h1>
        <p className="lead">Enter the OTP to verify your Email.</p>
      </div>
      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <Formik
              initialValues={{
                otp: "",
                submit: false,
              }}
              validationSchema={Yup.object().shape({
                otp: Yup.string().max(255).required("Email is required"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting },
              ) => {
                // recaptchaRef.current.execute();
                const email = localStorage.getItem("email");

                const data = {
                  OTP: values.otp,
                  email,
                  // captchaToken: token,
                };
                await axios
                  .post(authConfig.server + "/api/v1/auth/verifyEmail", data)
                  .then((res) => {
                    Cookies.set("token", res.data.accessToken);
                    notyf.open({
                      type: "success",
                      message: res?.data?.message,
                    });
                    localStorage.removeItem("email");
                    checkUserLogin();
                    navigate("/");
                  })
                  .catch((err) => {
                    const message = err.message || "Something went wrong";
                    recaptchaRef.current.resetCaptcha();
                    notyf.open({
                      type: "danger",
                      message: err.response?.data?.message,
                    });
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="text"
                      name="otp"
                      label="OTP"
                      value={values.otp}
                      isInvalid={Boolean(touched.otp && errors.otp)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.otp && (
                      <Form.Control.Feedback type="invalid">
                        {errors.otp}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  {/* <div className="text-center ">
                    <HCaptcha
                      sitekey={authConfig.RECAPTCHA_KEY}
                      onVerify={setToken}
                      ref={recaptchaRef}
                      data-theme="dark"
                      data-size="invisible"
                    />
                  </div> */}
                  <div className="text-center ">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      Verify
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card.Body>
      </Card>

      {/* <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={authConfig.RECAPTCHA_KEY}
        size="invisible"
      /> */}
    </>
  );
}

export default ResetPassword;
