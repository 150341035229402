import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import CardHeader from "react-bootstrap/esm/CardHeader";
import { Helmet } from "react-helmet-async";
import LabelAPI from "./LabelAPI";
import CreateOrder from "./CreateOrder";
import SingleOrder from "./SingleOrderinfo";
import DownloadLabel from "./DownloadLabel";
import { useContext } from "react";
import NotyfContext from "../../contexts/NotyfContext";
import Loader from "../../components/Loader";

const APIPage = () => {
  const notyf = useContext(NotyfContext);
  const data = localStorage.getItem("userdata");
  const apiKey = JSON.parse(data);

  const copyToClipboard = (content) => {
    navigator.clipboard.writeText(content);

    notyf.open({
      type: "success",
      message: "Copied to clipboard!",
    });
  };

  console.log(apiKey);

  if (apiKey === null) {
    return <Loader />;
  }
  return (
    <>
      <Helmet title="API" />
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h3 className="page-title text-info">API Documentation</h3>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Form.Group className="mb-3 ">
                <h4 className=" text-warning">API Key</h4>
                <InputGroup>
                  <FormControl
                    size="lg"
                    className="text-warning"
                    type="text"
                    name="apiKey"
                    value={apiKey.api_key}
                  />

                  <InputGroup.Text
                    className="text-warning"
                    onClick={() => copyToClipboard(apiKey.api_key)}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <LabelAPI />
          <SingleOrder />
          <DownloadLabel />
        </Col>
        <Col>
          <CreateOrder />
        </Col>
      </Row>
    </>
  );
};

export default APIPage;
