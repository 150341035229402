import React, { useEffect, useState } from "react";
import { Badge, Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Loader from "../../components/Loader";
import axios from "axios";
import { authConfig } from "../../config";
import Cookies from "js-cookie";

const LabelAPI = () => {
  const [key, setKey] = useState("Success");
  const [loading, setLoading] = useState("");
  const [types, setTypes] = useState([]);

  useEffect(() => {
    readLabelTypes();
  }, []);

  const readLabelTypes = async () => {
    setLoading(<Loader />);
    await axios
      .get(authConfig.server + "/api/v1/labeltype/read", {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setLoading("");
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        setLoading("");
        console.log(err);
      });
  };

  console.log(types);
  return (
    <Card>
      <Card.Body>
        <h3 className=" text-info mb-2">Label Types</h3>

        <h5 className="mb-2">
          Get list of all Label Types available in the system including prices.
        </h5>
        <Container fluid>
          <Row className="mb-2 p-2 border border-primary rounded">
            <h4 className="mb-2 fw-bold text-success">
              All Label Types available
            </h4>

            {types.map((e) => {
              return (
                <Col xs={6} className="mb-2">
                  <div
                    className="border border-primary rounded p-1 bg-primary"
                    style={{ "--bs-bg-opacity": 0.5 }}
                  >
                    <h5>
                      {" "}
                      <span className="text-muted me-1"> Name:</span>
                      {e.name}
                    </h5>
                    <h5>
                      {" "}
                      <span className="text-muted me-1"> Max Weight:</span>
                      {e.maxWeight} Lbs
                    </h5>

                    <h5>
                      <span className="text-muted me-1">UID:</span> {e.uid}
                    </h5>
                    <div className="d-flex justify-content-between">
                      <h5 className="text-muted">Weight Range</h5>
                      <Row>
                        {e.prices.map((e) => {
                          return (
                            <Col xs={12} className="text-end">
                              <h5>
                                {e.fromWeight} - {e.toWeight} Lbs
                              </h5>
                            </Col>
                          );
                        })}
                      </Row>
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Container>
        <div className=" mb-3">
          <h4 className="d-flex gap-3 ">
            <Badge bg="success">GET</Badge>
            <Badge bg="secondary" className="mr-4">
              https://api.goship.gg/api/v2/order/readLabels
            </Badge>
          </h4>
          <h4 className="d-flex gap-2 ">
            <b>Headers:</b>
            <Badge bg="warning">x-api-key : "API KEY"</Badge>
          </h4>
        </div>
        <h5>Response</h5>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="Success" title="Success">
            <textarea
              disabled
              style={{ height: "20rem", fontFamily: "monospace" }}
              className="w-100 text-warning bg-dark rounded-2 p-3 mt-3"
              value={`{	
                         "message": "Labels fetched successfully",
	                    "labels": [
	                    {
                            "name": "UPS Ground ®",
                            "uid": "ups_ground",
                            "maxWeight": 150,
                            "prices": [
                            {
                                "price": 8,
                                "fromWeight": 1,
                                "toWeight": 120,
                            }
                            ],
                        }
                        ]
                    }`}
            />
          </Tab>
          <Tab eventKey="Failure" title="Failure">
            <textarea
              disabled
              style={{ height: "20rem", fontFamily: "monospace" }}
              className="w-100 text-danger bg-dark rounded-2 p-3 mt-3"
              value={`
              {
    "status": 400 || 401 || 404,
    "message": ""
}
                    
                    `}
            />
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default LabelAPI;
