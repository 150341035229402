import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const Pagination = ({ page, setPage, totalPages }) => {
  return totalPages ? (
    <div className="d-flex justify-content-end mt-5">
      <nav>
        <ul className="pagination gap-2">
          <li className={page === 1 ? "page-item disabled" : "page-item"}>
            <Button
              className={
                page === 1
                  ? "page-item disabled btn-secondary"
                  : "page-item btn-primary "
              }
              onClick={(e) => {
                setPage(page - 1);
              }}
            >
              <FontAwesomeIcon icon={faAngleDoubleLeft} />
            </Button>
          </li>

          <li className="page-item active rounded-circle" aria-current="page">
            <Button className=" rounded-circle">{page}</Button>
          </li>

          <li
            className={
              totalPages === 1 || totalPages === page
                ? "page-item disabled text-light"
                : "page-item text-info"
            }
          >
            <Button
              className={
                totalPages === 1 || totalPages === page
                  ? "page-item disabled btn-secondary"
                  : "page-item btn-primary "
              }
              tabIndex={-1}
              disabled={totalPages === 1 || totalPages === page}
              onClick={(e) => {
                setPage(page + 1);
              }}
            >
              <FontAwesomeIcon icon={faAngleDoubleRight} />
            </Button>
          </li>
        </ul>
      </nav>
    </div>
  ) : (
    ""
  );
};

export default Pagination;
