import React from "react";
import { Helmet } from "react-helmet-async";
import { Card } from "react-bootstrap";
import logo from "../../assets/img/logo.svg";

import SignUp from "../../components/auth/SignUp";

const SignUpPage = () => (
  <React.Fragment>
    <Helmet title="Sign Up" />

    <div className="text-center mt-4">
      <h1 className="h2">Get started</h1>
      <p className="lead">
        Start creating the best possible user experience for you customers.
      </p>
    </div>

    <Card>
      <Card.Body>
        <div className="m-sm-4">
          <div className="text-center">
            <img
              src={logo}
              alt="Chris Wood"
              className="img-fluid rounded-circle"
              width="132"
              height="132"
            />
          </div>
          <SignUp />
        </div>
      </Card.Body>
    </Card>
  </React.Fragment>
);

export default SignUpPage;
