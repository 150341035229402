import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import {
  Alert,
  Button,
  Card,
  Form,
  FormControl,
  InputGroup,
} from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import Cookies from "js-cookie";
import { authConfig } from "../../config";
import axios from "axios";
import { useRef } from "react";
import { Helmet } from "react-helmet-async";
import NotyfContext from "../../contexts/NotyfContext";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useState } from "react";
import { useEffect } from "react";
import logo from "../../assets/img/logo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
function ConfirmPassword() {
  const navigate = useNavigate();
  const { resetPassword } = useAuth();
  const recaptchaRef = useRef(null);
  const notyf = useContext(NotyfContext);
  const [token, setToken] = useState(null);
  const [showPass, setShowPass] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);

  return (
    <>
      <Helmet title="New Password" />

      <Card>
        <Card.Body>
          <div className="m-sm-4">
            <div className="text-center mt-4">
              <h1 className="h2">Add New Password</h1>
              <p className="lead">Enter the new Password</p>
            </div>
            <div className="text-center">
              <img
                src={logo}
                alt="Chris Wood"
                className="img-fluid rounded-circle"
                width="80"
                height="80"
              />
            </div>
            <Formik
              initialValues={{
                otp: "",
                submit: false,
                newPassword: "",
                newConfirmPassword: "",
              }}
              validationSchema={Yup.object().shape({
                otp: Yup.string().max(255).required("Otp is required"),
                newPassword: Yup.string()
                  .max(255)
                  .required("Password is required"),
                newConfirmPassword: Yup.string()
                  .oneOf([Yup.ref("newPassword")])
                  .required("Please Re-Enter Password"),
              })}
              onSubmit={async (
                values,
                { setErrors, setStatus, setSubmitting },
              ) => {
                // recaptchaRef.current.execute();
                const email = localStorage.getItem("email");

                const data = {
                  email,
                  newPassword: values.newPassword,
                  OTP: values.otp,
                  // captchaToken: token,
                };
                await axios
                  .post(authConfig.server + "/api/v1/auth/resetPassword", data)
                  .then((res) => {
                    Cookies.set("token", res.data.accessToken);
                    notyf.open({
                      type: "success",
                      message: res?.data?.message,
                    });
                    localStorage.removeItem("email");
                    // recaptchaRef.current.resetCaptcha();
                    navigate("/");
                  })
                  .catch((err) => {
                    const message = err.message || "Something went wrong";
                    notyf.open({
                      type: "danger",
                      message: err.response?.data?.message,
                    });
                    setStatus({ success: false });
                    setErrors({ submit: message });
                    setSubmitting(false);
                  });
              }}
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
              }) => (
                <Form onSubmit={handleSubmit}>
                  {errors.submit && (
                    <Alert className="my-3" variant="danger">
                      <div className="alert-message">{errors.submit}</div>
                    </Alert>
                  )}
                  <Form.Group className="mb-3">
                    <Form.Label>OTP</Form.Label>
                    <Form.Control
                      type="text"
                      name="otp"
                      label="OTP"
                      value={values.otp}
                      isInvalid={Boolean(touched.otp && errors.otp)}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {!!touched.otp && (
                      <Form.Control.Feedback type="invalid">
                        {errors.otp}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>
                  <Form.Label>New Password</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      type={showPass ? "text" : "password"}
                      name="newPassword"
                      label="New Password"
                      value={values.newPassword}
                      isInvalid={Boolean(
                        touched.newPassword && errors.newPassword,
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                      {showPass ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </InputGroup.Text>
                    {!!touched.newPassword && (
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup className="mb-3">
                    <FormControl
                      type={showConfirmPass ? "text" : "password"}
                      name="newConfirmPassword"
                      label="Confrim Password"
                      value={values.newConfirmPassword}
                      isInvalid={Boolean(
                        touched.newConfirmPassword && errors.newConfirmPassword,
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    <InputGroup.Text
                      onClick={() => setShowConfirmPass(!showConfirmPass)}
                    >
                      {showPass ? (
                        <FontAwesomeIcon icon={faEyeSlash} />
                      ) : (
                        <FontAwesomeIcon icon={faEye} />
                      )}
                    </InputGroup.Text>
                    {!!touched.newConfirmPassword && (
                      <Form.Control.Feedback type="invalid">
                        {errors.newConfirmPassword}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                  {/* <div className="text-center ">
                    <HCaptcha
                      sitekey={authConfig.RECAPTCHA_KEY}
                      onVerify={setToken}
                      ref={recaptchaRef}
                      data-theme="dark"
                      data-size="invisible"
                    />
                  </div> */}
                  <div className="text-center ">
                    <Button
                      type="submit"
                      variant="primary"
                      size="lg"
                      disabled={isSubmitting}
                    >
                      Verify
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Card.Body>
      </Card>

      {/* <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={authConfig.RECAPTCHA_KEY}
        size="invisible"
      /> */}
    </>
  );
}

export default ConfirmPassword;
