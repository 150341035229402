import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Card, Col, Form, FormControl, InputGroup, Row } from "react-bootstrap";
import Loader from "../../../components/Loader";
import { authConfig } from "../../../config";
import NotyfContext from "../../../contexts/NotyfContext";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRandom } from "@fortawesome/free-solid-svg-icons";
// import Loader from "../../../components/Loader";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { Countries } from "../../../constants/index";

const IntLabel = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState("");
  const [addresses, setAddresses] = useState([]);
  const [address, setAddress] = useState({});
  const [address1, setAddress1] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [types, setTypes] = useState([]);
  const [type, setType] = useState("");
  const [stats, setStats] = useState({});
  const notyf = useContext(NotyfContext);
  const [finalPrice, setFinalPrice] = useState(0);
  const [Type_id, setType_id] = useState("");
  const [Weight, setWeight] = useState(0);
  const [reference1, setReference1] = useState("");
  const [reference2, setReference2] = useState("");
  const [length, setLength] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [description, setDescription] = useState("");
  const [selectedCountryTo, setSelectedCountryTo] = useState("");
  const [selectedCountryFrom, setSelectedCountryFrom] = useState("");
  const [activeType, setActiveType] = useState({});
  const inputRefFrom = useRef();
  const inputRefTo = useRef();
  const [states, setStates] = useState([
    {
      ID: "AK",
      Name: "Alaska",
      Country: "US",
    },
    {
      ID: "AL",
      Country: "US",
      Name: "Alabama",
    },
    {
      ID: "AR",
      Country: "US",
      Name: "Arkansas",
    },
    {
      ID: "AZ",
      Country: "US",
      Name: "Arizona",
    },
    {
      ID: "CA",
      Country: "US",
      Name: "California",
    },
    {
      ID: "CO",
      Country: "US",
      Name: "Colorado",
    },
    {
      ID: "CT",
      Country: "US",
      Name: "Connecticut",
    },
    {
      ID: "DC",
      Country: "US",
      Name: "District Of Columbia",
    },
    {
      ID: "DE",
      Country: "US",
      Name: "Delaware",
    },
    {
      ID: "FL",
      Country: "US",
      Name: "Florida",
    },
    {
      ID: "GA",
      Country: "US",
      Name: "Georgia",
    },
    {
      ID: "HI",
      Country: "US",
      Name: "Hawaii",
    },
    {
      ID: "IA",
      Country: "US",
      Name: "Iowa",
    },
    {
      ID: "ID",
      Country: "US",
      Name: "Idaho",
    },
    {
      ID: "IL",
      Country: "US",
      Name: "Illinois",
    },
    {
      ID: "IN",
      Country: "US",
      Name: "Indiana",
    },
    {
      ID: "KS",
      Country: "US",
      Name: "Kansas",
    },
    {
      ID: "KY",
      Country: "US",
      Name: "Kentucky",
    },
    {
      ID: "LA",
      Country: "US",
      Name: "Louisiana",
    },
    {
      ID: "MA",
      Country: "US",
      Name: "Massachusetts",
    },
    {
      ID: "MD",
      Country: "US",
      Name: "Maryland",
    },
    {
      ID: "ME",
      Country: "US",
      Name: "Maine",
    },
    {
      ID: "MI",
      Name: "Michigan",
      Country: "US",
    },
    {
      ID: "MN",
      Country: "US",
      Name: "Minnesota",
    },
    {
      ID: "MO",
      Country: "US",
      Name: "Missouri",
    },
    {
      ID: "MS",
      Country: "US",
      Name: "Mississippi",
    },
    {
      ID: "MT",
      Country: "US",
      Name: "Montana",
    },
    {
      ID: "NC",
      Country: "US",
      Name: "North Carolina",
    },
    {
      ID: "ND",
      Country: "US",
      Name: "North Dakota",
    },
    {
      ID: "NE",
      Country: "US",
      Name: "Nebraska",
    },
    {
      ID: "NH",
      Country: "US",
      Name: "New Hampshire",
    },
    {
      ID: "NJ",
      Country: "US",
      Name: "New Jersey",
    },
    {
      ID: "NM",
      Country: "US",
      Name: "New Mexico",
    },
    {
      ID: "NV",
      Country: "US",
      Name: "Nevada",
    },
    {
      ID: "NY",
      Country: "US",
      Name: "New York",
    },
    {
      ID: "OH",
      Name: "Ohio",
      Country: "US",
    },
    {
      ID: "OK",
      Name: "Oklahoma",
      Country: "US",
    },
    {
      ID: "OR",
      Name: "Oregon",
      Country: "US",
    },
    {
      ID: "PA",
      Name: "Pennsylvania",
      Country: "US",
    },
    {
      ID: "PR",
      Country: "US",
      Name: "Puerto Rico",
    },
    {
      ID: "RI",
      Country: "US",
      Name: "Rhode Island",
    },
    {
      ID: "SC",
      Country: "US",
      Name: "South Carolina",
    },
    {
      ID: "SD",
      Country: "US",
      Name: "South Dakota",
    },
    {
      ID: "TN",
      Country: "US",
      Name: "Tennessee",
    },
    {
      ID: "TX",
      Country: "US",
      Name: "Texas",
    },
    {
      ID: "UT",
      Country: "US",
      Name: "Utah",
    },
    {
      ID: "VA",
      Country: "US",
      Name: "Virginia",
    },
    {
      ID: "VT",
      Country: "US",
      Name: "Vermont",
    },
    {
      ID: "WA",
      Country: "US",
      Name: "Washington",
    },
    {
      ID: "WI",
      Country: "US",
      Name: "Wisconsin",
    },
    {
      ID: "WV",
      Country: "US",
      Name: "West Virginia",
    },
    {
      ID: "WY",
      Country: "US",
      Name: "Wyoming",
    },

    // write canada provinces here
    {
      ID: "AB",
      Country: "CA",
      Name: "Alberta",
    },
    {
      ID: "BC",
      Country: "CA",
      Name: "British Columbia",
    },
    {
      ID: "MB",
      Country: "CA",
      Name: "Manitoba",
    },
    {
      ID: "NB",
      Country: "CA",
      Name: "New Brunswick",
    },
    {
      ID: "NL",
      Country: "CA",
      Name: "Newfoundland and Labrador",
    },
    {
      ID: "NS",
      Country: "CA",
      Name: "Nova Scotia",
    },
    {
      ID: "NT",
      Country: "CA",
      Name: "Northwest Territories",
    },
    {
      ID: "NU",
      Country: "CA",
      Name: "Nunavut",
    },
    {
      ID: "ON",
      Country: "CA",
      Name: "Ontario",
    },
    {
      ID: "PE",
      Country: "CA",
      Name: "Prince Edward Island",
    },
    {
      ID: "QC",
      Country: "CA",
      Name: "Quebec",
    },
    {
      ID: "SK",
      Country: "CA",
      Name: "Saskatchewan",
    },
    {
      ID: "YT",
      Country: "CA",
      Name: "Yukon",
    },
  ]);

  const [From, setFrom] = useState({
    FromName: "",
    FromStreet: "",
    FromStreet2: "",
    FromPhone: "",
    FromCity: "",
    FromState: "",
    FromZip: "",
    FromEmail: "",
    FromCountry: "",
  });
  const [To, setTo] = useState({
    ToName: "",
    ToStreet: "",
    ToPhone: "",
    ToStreet2: "",
    ToCity: "",
    ToState: "",
    ToZip: "",
    ToEmail: "",
    ToCountry: "",
  });

  const getAddresses = async () => {
    setLoading(<Loader />);
    await axios
      .get(authConfig.server + `/api/v1/address/readAll?limit=${30}`, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setLoading("");
        setAddresses(res.data.addresses);
      })
      .catch((err) => {
        setLoading("");
        console.log(err);
      });
  };

  const readLabelTypes = async () => {
    setLoading(<Loader />);
    await axios
      .get(authConfig.server + "/api/v1/labeltype/read?init=tiny", {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setLoading("");
        setTypes(res.data.labelTypes);
      })
      .catch((err) => {
        setLoading("");
        console.log(err);
      });
  };

  useEffect(() => {
    getAddresses();
    readLabelTypes();
  }, []);
  useEffect(() => {
    setFrom({
      FromName: address.name,
      FromStreet: address.street,
      FromStreet2: address.street2,
      FromCity: address.city,
      FromState: address.state,
      FromZip: address.zip,
      FromPhone: address.phone,
      FromCompany: address.company,
      FromCountry: address.country,
    });
    setSelectedCountryFrom(address.country);
  }, [address]);
  useEffect(() => {
    setTo({
      ToName: address1.name,
      ToStreet: address1.street,
      ToStreet2: address1.street2,
      ToCity: address1.city,
      ToState: address1.state,
      ToPhone: address1.phone,
      ToZip: address1.zip,
      ToCompany: address1.company,
      ToCountry: address1.country,
    });
    setSelectedCountryTo(address1.country);
  }, [address1]);

  useEffect(() => {
    // find the type of label and max weight
    const getPrice = async () => {
      const data = {
        Type: Type_id,
        Weight,
      };

      await axios
        .post(authConfig.server + "/api/v1/order/price", data, {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((res) => {
          setFinalPrice(res.data.price);
        })
        .catch((err) => {
          console.log(err);
          setFinalPrice(0);
        });
    };

    getPrice();
  }, [Type_id, Weight]);

  // create order
  const createOrder = async (e) => {
    e.preventDefault();
    setLoading(<Loader />);
    const data = {
      price: finalPrice,
      Type: e.target.type.value,
      Weight: Weight,
      ToName: To.ToName,
      ToPhone: To.ToPhone,
      ToStreet: To.ToStreet,
      ToStreet2: To.ToStreet2,
      ToCity: To.ToCity,
      ToState: To.ToState,
      ToZip: To.ToZip,
      ToEmail: To.ToEmail,
      ToCountry: To.ToCountry,
      FromName: From.FromName,
      FromStreet: From.FromStreet,
      FromStreet2: From.FromStreet2,
      FromCity: From.FromCity,
      FromState: From.FromState,
      FromZip: From.FromZip,
      FromPhone: From.FromPhone,
      FromEmail: From.FromEmail,
      FromCountry: From.FromCountry,
      height: height || 0,
      width: width || 0,
      length: length || 0,
      description: description || "",
      reference1: reference1 || "",
    };

    console.log(data);

    await axios
      .post(authConfig.server + "/api/v1/order/create-int", data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        if (res.data.message === "Order created successfully") {
          setLoading("");
          notyf.open({
            type: "success",
            message: res?.data?.message,
          });
          navigate("/int-order-history");
        }
      })
      .catch((err) => {
        setLoading("");
        console.log(err);
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
      });
  };

  const generateMobileNumberFrom = () => {
    let randomPhoneNumber = Math.floor(Math.random() * 9000000000) + 100000000;

    setFrom({
      ...From,
      FromPhone: randomPhoneNumber,
    });
  };
  const generateMobileNumberTo = () => {
    let randomPhoneNumber = Math.floor(Math.random() * 9000000000) + 100000000;

    setTo({
      ...To,
      ToPhone: randomPhoneNumber,
    });
  };

  const handlePlaceChangedFrom = () => {
    const place = inputRefFrom.current.getPlace();
    if (place) {
      let city = "";
      let state = "";
      let zip = "";

      place?.address_components?.forEach((p) => {
        if (p?.types[0] === "locality") city = p?.short_name || "";
        if (p?.types[0] === "administrative_area_level_1")
          state = p?.short_name || "";
        if (p?.types[0] === "postal_code") zip = p?.short_name || "";
      });

      setFrom({
        ...From,
        FromStreet: place.formatted_address.split(",")[0],
        FromStreet2:
          place.formatted_address.split(",").length > 4
            ? place.formatted_address.split(",")[1]
            : "",
        FromCity: city,
        FromState: state,
        FromZip: zip,
      });
    }
  };

  const handlePlaceChangedTo = () => {
    const place = inputRefTo.current.getPlace();
    if (place) {
      let city = "";
      let state = "";
      let zip = "";

      place?.address_components?.forEach((p) => {
        if (p?.types[0] === "locality") city = p?.short_name || "";
        if (p?.types[0] === "administrative_area_level_1")
          state = p?.short_name || "";
        if (p?.types[0] === "postal_code") zip = p?.short_name || "";
      });

      setTo({
        ...To,
        ToStreet: place.formatted_address.split(",")[0],
        ToStreet2:
          place.formatted_address.split(",").length > 4
            ? place.formatted_address.split(",")[1]
            : "",
        ToCity: city,
        ToState: state,
        ToZip: zip,
      });
    }
  };
  console.log(type);
  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title text-info">Create International Label</h4>
          </div>
        </Col>
      </Row>
      <LoadScript
        googleMapsApiKey={"AIzaSyBwQ1HNs493InlRbW2Mutdq_mkeB4pYS9U"}
        libraries={["places"]}
      >
        <form onSubmit={createOrder}>
          {loading}
          <Card>
            <Card.Body>
              <Row className="">
                <Col xs={12} md={5} lg={5}>
                  <div className="form-group">
                    <label>Type</label>
                    <select
                      name="type"
                      id=""
                      className="form-control"
                      onChange={(e) => {
                        setType_id(e.target.value);

                        setActiveType(
                          types.find((type) => {
                            return type._id === e.target.value;
                          }),
                        );
                      }}
                    >
                      <option value="">Select type</option>
                      {types.map((type) => (
                        <option key={type._id} value={type._id}>
                          {type.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </Col>
                <Col xs={12} md={5} lg={5}>
                  <div className="form-group">
                    <label>Weight</label>
                    <input
                      type="number"
                      name="Weight"
                      className="form-control"
                      placeholder={`Package Weight${
                        activeType?.maxWeight
                          ? " (" +
                            activeType?.maxWeight +
                            (activeType?.uid?.includes("first_class") ||
                            activeType?.uid?.includes("ground_oz")
                              ? " Oz"
                              : " Lbs") +
                            " Max)"
                          : ""
                      }*`}
                      required
                      onChange={(e) => {
                        setWeight(e.target.value);
                      }}
                    />
                  </div>
                </Col>
                <Col xs={12} md={2} lg={2}>
                  <div className=" d-flex text-align-center align-center mt-3">
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "1.5rem",
                      }}
                    >
                      Price:&nbsp;
                    </p>
                    <p
                      className="text-primary"
                      style={{
                        fontSize: "1.5rem",
                      }}
                    >
                      ${finalPrice}
                    </p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} md={6} lg={6}>
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title text-center">From</h2>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Select a saved address</label>

                        <select
                          name="address"
                          id=""
                          className="form-control"
                          onChange={(e) => {
                            setAddress(
                              addresses.find((a) => a._id === e.target.value),
                            );
                          }}
                        >
                          <option value="" disabled selected>
                            Select address
                          </option>
                          {addresses.map((address) => (
                            <option key={address._id} value={address._id}>
                              {address.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Country</label>
                        <select
                          className="form-control"
                          name="FromCountry"
                          placeholder="Country"
                          required
                          value={From.FromCountry}
                          onChange={(e) => {
                            setSelectedCountryFrom(e.target.value);
                            setFrom({ ...From, FromCountry: e.target.value });
                          }}
                        >
                          <option value="" disabled selected>
                            Select Country
                          </option>
                          {Countries?.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}

                          {/* <option value="US">United States</option>
                          <option value="CA">Canada</option> */}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter name"
                          name="FromName"
                          value={From.FromName}
                          onChange={(e) => {
                            setFrom({ ...From, FromName: e.target.value });
                          }}
                          required
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email name (Required for DHL services)"
                          name="FromEmail"
                          value={From.FromEmail}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <Form.Group className="mb-3">
                        <Form.Label className="mb-0">Phone</Form.Label>
                        <InputGroup>
                          <FormControl
                            size="lg"
                            type="number"
                            name="FromPhone"
                            placeholder="Enter your Phone"
                            value={From.FromPhone}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />

                          <InputGroup.Text
                            onClick={() => generateMobileNumberFrom()}
                          >
                            <FontAwesomeIcon icon={faRandom} />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <div className="form-group mb-3">
                        <Autocomplete
                          onLoad={(ref) => (inputRefFrom.current = ref)}
                          onPlaceChanged={handlePlaceChangedFrom}
                          restrictions={{ country: "us" }}
                        >
                          <>
                            <label htmlFor="order-id">Address 1</label>
                            <input
                              type="text"
                              className="form-control"
                              id="fromAddress"
                              placeholder="Enter Address 1"
                              name="FromStreet"
                              value={From.FromStreet}
                              onChange={(e) => {
                                setFrom({
                                  ...From,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                              required
                            />
                          </>
                        </Autocomplete>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Street 2 (optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fromAddress2"
                          placeholder="Address line 2"
                          name="FromStreet2"
                          value={From.FromStreet2}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">ZIP Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fromzipCode"
                          placeholder="ZipCode"
                          name="FromZip"
                          required
                          value={From.FromZip}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="fromCity"
                          placeholder="City"
                          name="FromCity"
                          required
                          value={From.FromCity}
                          onChange={(e) => {
                            setFrom({
                              ...From,
                              [e.target.name]: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">State</label>
                        {selectedCountryFrom === "Canada" ? (
                          <select
                            name="FromState"
                            id="FromState"
                            className="form-control"
                            required
                            value={From.FromState}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select state</option>

                            {states
                              .filter((state) => state.Country === "CA")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                          </select>
                        ) : selectedCountryFrom === "United States" ? (
                          <select
                            name="FromState"
                            id="FromState"
                            className="form-control"
                            required
                            value={From.FromState}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          >
                            <option value="">Select state</option>

                            {states
                              .filter((state) => state.Country === "US")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="FromState"
                            placeholder="State"
                            name="FromState"
                            required
                            value={From.FromState}
                            onChange={(e) => {
                              setFrom({
                                ...From,
                                [e.target.name]: e.target.value,
                              });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <div className="card">
                    <div className="card-body">
                      <h2 className="card-title text-center">To</h2>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Select a saved address</label>
                        <select
                          name="address"
                          id=""
                          className="form-control"
                          onChange={(e) => {
                            setAddress1(
                              addresses.find((a) => a._id === e.target.value),
                            );
                          }}
                        >
                          <option value="" disabled selected>
                            Select address
                          </option>
                          {addresses.map((address) => (
                            <option key={address._id} value={address._id}>
                              {address.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Country</label>
                        <select
                          className="form-control"
                          name="ToCountry"
                          placeholder="Country"
                          required
                          value={To.ToCountry}
                          onChange={(e) => {
                            setSelectedCountryTo(e.target.value);
                            setTo({ ...To, ToCountry: e.target.value });
                          }}
                        >
                          <option value="" disabled selected>
                            Select Country
                          </option>
                          {Countries?.map((type) => (
                            <option key={type} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Name</label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Enter name"
                          name="ToName"
                          required
                          value={To.ToName}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Email</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter Email name (Required for DHL services)"
                          name="ToEmail"
                          value={To.ToEmail}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>

                      <Form.Group className="mb-3">
                        <Form.Label className="mb-0">Phone</Form.Label>
                        <InputGroup>
                          <FormControl
                            size="lg"
                            type="number"
                            name="ToPhone"
                            placeholder="Enter your Phone"
                            value={To.ToPhone}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                          <InputGroup.Text
                            onClick={() => generateMobileNumberTo()}
                          >
                            <FontAwesomeIcon icon={faRandom} />
                          </InputGroup.Text>
                        </InputGroup>
                      </Form.Group>
                      <div className="form-group mb-3">
                        <Autocomplete
                          onLoad={(ref) => (inputRefTo.current = ref)}
                          onPlaceChanged={handlePlaceChangedTo}
                          restrictions={{ country: "us" }}
                        >
                          <>
                            <label htmlFor="order-id">Address 1</label>
                            <input
                              type="text"
                              className="form-control"
                              id="toAddress"
                              placeholder="Enter Address 1"
                              name="ToStreet"
                              required
                              value={To.ToStreet}
                              onChange={(e) => {
                                setTo({
                                  ...To,
                                  [e.target.name]: e.target.value,
                                });
                              }}
                            />
                          </>
                        </Autocomplete>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">Street 2 (optional)</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ToStreet2"
                          placeholder="Address line 2"
                          name="ToStreet2"
                          value={To.ToStreet2}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>

                      <div className="form-group mb-3">
                        <label htmlFor="order-id">ZIP Code</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ToZip"
                          placeholder="Zip Code"
                          name="ToZip"
                          required
                          value={To.ToZip}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">City</label>
                        <input
                          type="text"
                          className="form-control"
                          id="ToCity"
                          placeholder="City"
                          name="ToCity"
                          required
                          value={To.ToCity}
                          onChange={(e) => {
                            setTo({ ...To, [e.target.name]: e.target.value });
                          }}
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="order-id">State</label>
                        {selectedCountryTo === "Canada" ? (
                          <select
                            name="ToState"
                            id="ToState"
                            className="form-control"
                            required
                            value={To.ToState}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          >
                            <option value="">Select state</option>

                            {states
                              .filter((state) => state.Country === "CA")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                          </select>
                        ) : selectedCountryTo === "United States" ? (
                          <select
                            name="ToState"
                            id="ToState"
                            className="form-control"
                            required
                            value={To.ToState}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          >
                            <option value="">Select state</option>

                            {states
                              .filter((state) => state.Country === "US")
                              .map((state, index) => (
                                <option key={index} value={state.ID}>
                                  {state.Name}
                                </option>
                              ))}
                          </select>
                        ) : (
                          <input
                            type="text"
                            className="form-control"
                            id="ToState"
                            placeholder="State"
                            name="ToState"
                            required
                            value={To.ToState}
                            onChange={(e) => {
                              setTo({ ...To, [e.target.name]: e.target.value });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <>
                    <Row className="g-3 row-cols-2 mb-2">
                      <Col xs={6}>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Notes</label>
                          <input
                            type="text"
                            className="form-control"
                            id="reference1"
                            placeholder="Enter Notes"
                            name="reference1"
                            onChange={(e) => {
                              setReference1(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={6}>
                        <div className="form-group mb-3">
                          <label htmlFor="order-id">Item Description</label>
                          <input
                            type="text"
                            className="form-control "
                            id="description"
                            placeholder="Enter Item Description"
                            name="description"
                            onChange={(e) => {
                              setDescription(e.target.value);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {activeType?.name?.includes("UPS") && (
            <Card>
              <Card.Body>
                <Row>
                  <Col xs={12} md={12} lg={12}>
                    <>
                      <Row className="g-3 row-cols-3 mb-2">
                        <Col>
                          <div className="form-group mb-3">
                            <label htmlFor="order-id">Length</label>
                            <input
                              type="number"
                              className="form-control"
                              id="length"
                              required
                              placeholder="Enter Length (inch) *"
                              name="length"
                              onChange={(e) => {
                                setLength(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group mb-3">
                            <label htmlFor="order-id">Width</label>
                            <input
                              type="number"
                              className="form-control"
                              id="width"
                              placeholder="Enter Width (inch) *"
                              name="width"
                              required
                              onChange={(e) => {
                                setWidth(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group mb-3">
                            <label htmlFor="order-id">Height</label>
                            <input
                              type="number"
                              className="form-control"
                              id="height"
                              placeholder="Enter Height (inch) *"
                              name="height"
                              required
                              onChange={(e) => {
                                setHeight(e.target.value);
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          )}

          {/* show balance and button to create order */}
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between">
                <span className=" d-flex  ">
                  <h1
                    className="text-muted"
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    Price:&nbsp;
                  </h1>
                  <h1
                    className="text-primary"
                    style={{
                      fontSize: "2rem",
                    }}
                  >
                    ${finalPrice}
                  </h1>
                </span>

                <button
                  className="btn btn-primary btn-xl "
                  type="submit"
                  disabled={isDisabled}
                >
                  Create Order
                </button>
              </div>
            </Card.Body>
          </Card>
        </form>
      </LoadScript>
    </>
  );
};

export default IntLabel;
