// @flow
import classNames from "classnames";
import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Spinner,
  InputGroup,
  Form,
  Button,
  Modal,
  Table,
  Badge,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { RowSelectionTable } from "../tables/RowSelection";
import { authConfig } from "../../config";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet-async";
import NotyfContext from "../../contexts/NotyfContext";
import Cookies from "js-cookie";
import Pagination from "../../components/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
/* orderdate column render */
const OrderDateColumn = ({ row }) => {
  return (
    <>
      <span>
        {new Date(row.original.createdAt).toLocaleDateString()}
        <small className="text-muted m-1">
          {new Date(row.original.createdAt).toLocaleTimeString()}
        </small>
      </span>
    </>
  );
};

/* order column render */
const OrderColumn = ({ row }) => {
  return (
    <>
      <p>{row.index + 1}</p>
    </>
  );
};

/* order Amount render */
const OrderAmount = ({ row }) => {
  return (
    <>
      <p>$ {row.original.amount + 1}</p>
    </>
  );
};

/* payment column render */
const PaymentStatusColumn = ({ row }) => {
  return (
    <>
      <h5>
        <span
          className={classNames("badge", {
            "badge-success-lighten": row.original.status === "paid",
            "badge-danger-lighten": row.original.status === "cancelled",
            "badge-warning-lighten": row.original.status === "pending",
          })}
        >
          {row.original.status === "paid" && (
            <i className="mdi mdi-cash me-1"></i>
          )}
          {row.original.status === "cancelled" && (
            <i className="mdi mdi-cancel me-1"></i>
          )}
          {row.original.status === "pending" && (
            <i className="mdi mdi-timer-sand me-1"></i>
          )}
          {row.original.status}
        </span>
      </h5>
    </>
  );
};

const columns = [
  {
    Header: "ID",
    accessor: "id",
    sort: false,
    Cell: OrderColumn,
  },
  {
    Header: "Amount",
    accessor: "amount",
    sort: false,
    Cell: OrderAmount,
  },
  {
    Header: "Payment Method",
    accessor: "payment_method",
    sort: false,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    sort: false,
    Cell: OrderDateColumn,
  },
  {
    Header: "Status",
    accessor: "payment_status",
    sort: false,
    Cell: PaymentStatusColumn,
  },
];

const paymentMethods = [
  {
    name: "CashApp",
    val: "cashapp",
    icon: "mdi mdi-cash-plus",
  },
  {
    name: "Coinbase",
    val: "coinbase",
    icon: "mdi mdi-credit-card-multiple",
  },
];

const DepositBalance = () => {
  const [paymentLoading, setPaymentLoading] = useState("");
  const [selectedPayementMethod, setSelectedPayementMethod] = useState(
    paymentMethods[0].val,
  );
  const [deposits, setDeposits] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [showCashApp, setShowCashApp] = useState(false);
  const [cashAppID, setCashAppID] = useState("");
  const [url, setUrl] = useState("");
  const [amount, setAmount] = useState(0);
  const [invoice, setInvoice] = useState({});
  const [cashLoader, setCashLoader] = useState(false);
  const [qr, setQr] = useState("");
  const [depositsLoading, setDepositsLoading] = useState("");
  const [minAmount, setMinAmount] = useState("");
  const notyf = useContext(NotyfContext);
  const navigate = useNavigate();
  const [cryptoBonuss, setCryptoBonus] = useState({});
  const [showCashAppModal, setShowCashAppModal] = useState(false);
  const onCloseCashAppModalModal = () => {
    setShowCashAppModal(false);
  };
  const onOpenCashAppModalModal = () => setShowCashAppModal(true);
  const readCashApp = async () => {
    await axios(authConfig.server + "/api/v1/invoice/read-cashapp", {
      headers: {
        Authorization: "Bearer " + Cookies.get("token"),
      },
    })
      .then((res) => {
        setCashAppID(res.data.credentials.cashappId);
        setQr(res.data.credentials.qr);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyCashAppPayment = async (e) => {
    e.preventDefault();
    setCashLoader(true);

    const params = {
      invoice: invoice._id,
      cashAppUrl: e.target.checkurl.value,
    };

    await axios
      .post(`${authConfig.server}/api/v1/invoice/verifyCashApp`, params, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setCashLoader(false);
        notyf.open({
          type: "success",
          message: res?.data?.message,
        });
        getDeposits();
      })
      .catch((err) => {
        setCashLoader(false);
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
        console.log(err);
      });
  };

  useEffect(() => {
    readCashApp();
    readCryptoBonus();
  }, []);

  useEffect(() => {
    getDeposits();
  }, [page]);

  const getDeposits = async () => {
    setDepositsLoading(<Spinner animation="grow" variant="primary" />);
    await axios
      .get(
        `${authConfig.server}/api/v1/invoice/read?page=${page}&limit=${10}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      )
      .then((res) => {
        setDeposits(res.data.invoices);
        setTotalPages(res.data.totalPages);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setDepositsLoading(""));
  };

  const AddBalance = async (e) => {
    e.preventDefault();
    setPaymentLoading(<Spinner animation="grow" variant="primary" />);

    const data = {
      amount,
      type: selectedPayementMethod,
    };

    await axios
      .post(authConfig.server + "/api/v1/invoice/create", data, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setPaymentLoading("");
        getDeposits();
        notyf.open({
          type: "success",
          message: res?.data?.message,
        });

        // if (data.type === "coinbase") {
        //   window.location.href = res.data?.data?.hosted_url;
        // }
        if (data.type === "cashapp") {
          setUrl(res.data.data.hosted_url);
          setInvoice(res.data.data.invoice);
          setShowCashApp(true);
          onOpenCashAppModalModal(true);
        }
      })
      .catch((err) => {
        setPaymentLoading("");
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
        console.log(err);
      });
    setTimeout(() => {
      setPaymentLoading("");
      e.target.reset();
    }, 3000);
  };

  const readCryptoBonus = async () => {
    await axios
      .get(authConfig.server + "/api/v1/admin/bonus", {
        headers: {
          Authorization: "Bearer " + Cookies.get("footprint"),
        },
      })
      .then((res) => {
        setCryptoBonus(res.data.topup);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Helmet title="Deposit Balance" />
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="page-title text-info mb-3">Deposit</h4>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3">Deposit Balance</h4>

              <div className="border p-3 mb-3 rounded">
                {paymentLoading !== "" ? (
                  paymentLoading
                ) : (
                  <form onSubmit={AddBalance}>
                    <InputGroup className="mb-3">
                      <Form.Control
                        placeholder="0.00"
                        className="input-field"
                        type="number"
                        name="amount"
                        onChange={(e) => setAmount(e.target.value)}
                        required
                      />
                      <InputGroup.Text
                        style={{ width: "auto", borderLeft: "none" }}
                        className="input-field"
                      >
                        USD
                      </InputGroup.Text>
                    </InputGroup>
                    <h6 className="mb-3 fz-lg">Choose Payment Method</h6>
                    <Row className="mb-3 justify-content-center">
                      <div className="d-flex justify-content-around">
                        <div className="d-flex flex-column">
                          <Button
                            size="md"
                            variant="success"
                            onClick={() => setSelectedPayementMethod("cashapp")}
                            type="submit"
                            disabled={paymentLoading !== ""}
                          >
                            {/* {cashAppLoading !== "" ? (
                          cashAppLoading
                        ) : ( */}
                            <div className="d-flex p-1 gap-2 align-items-center">
                              <FontAwesomeIcon icon={faMoneyBill} />
                              <span>CashApp</span>
                            </div>
                            {/* )} */}
                          </Button>
                        </div>
                        {/* <div className="d-flex flex-column gap-1">
                          <Button
                            size="md"
                            disabled={paymentLoading !== ""}
                            variant="info"
                            onClick={() =>
                              setSelectedPayementMethod("coinbase")
                            }
                            type="submit"
                          >
                            <div className="d-flex p-1 gap-2 align-items-center">
                              <FontAwesomeIcon icon={faCoins} />
                              <span>Coinbase</span>
                            </div>
                          </Button>
                          <p className="text-warning text-center h4">
                            {" "}
                            {cryptoBonuss.cryptoBonus}% Bonus
                          </p>
                        </div> */}
                      </div>
                    </Row>
                  </form>
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg={8}>
          <Card>
            <Card.Body>
              <h4 className="header-title mb-3 mt-2">Deposit History</h4>
              <div className="table-responsive" style={{ overflowY: "hidden" }}>
                <Table className="mb-0 mt-3" striped>
                  <thead className="table-dark">
                    <tr>
                      <th>ID</th>
                      <th>Amount</th>
                      <th>PAYMENT METHOD</th>
                      <th>CreatedAt</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositsLoading}
                    {deposits?.map((record, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>${record.amount}</td>
                          <td>{record.payment_method}</td>
                          <td>
                            {new Date(record.updatedAt).toLocaleDateString()}
                          </td>
                          <td>
                            <h5>
                              {record.status === "paid" ? (
                                <Badge bg="success" pill>
                                  {" "}
                                  {record.status}
                                </Badge>
                              ) : record.status === "cancelled" ? (
                                <Badge bg="danger" pill>
                                  {" "}
                                  {record.status}
                                </Badge>
                              ) : (
                                <Badge bg="warning" pill>
                                  {" "}
                                  {record.status}
                                </Badge>
                              )}
                            </h5>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={showCashAppModal}
        onHide={onCloseCashAppModalModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered={true}
      >
        <CashAppConfirm
          cashAppID={cashAppID}
          amount={amount}
          url={url}
          qr={qr}
          invoice={invoice}
          cashLoader={cashLoader}
          verifyCashAppPayment={verifyCashAppPayment}
        />
      </Modal>
    </>
  );
};

export default DepositBalance;

const CashAppConfirm = ({
  cashAppID,
  amount,
  url,
  qr,
  invoice,
  cashLoader,
  verifyCashAppPayment,
}) => {
  return (
    <>
      <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
        <Modal.Title id="modal-title">
          <h3>Verify Cashapp URL </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div class="d-flex flex-column align-items-center">
          <img
            src={authConfig.server + "/" + qr}
            alt=""
            className="img-fluid"
            style={{
              height: "200px",
              width: "200px",
            }}
          />
          <h3
            className="mt-1"
            style={{
              color: "orange",
            }}
          >
            {cashAppID}
          </h3>

          <h6 className="mb-1">
            <span className="text-muted h5">Amount:</span>{" "}
            <span className="text-success h4">${amount}</span>
          </h6>

          <h6>
            <span className="text-muted">Note:</span>{" "}
            <span className="text-warning">{url || "sdsdsd"}</span>
          </h6>
          <p
            className="mt-1 text-muted"
            style={{
              fontSize: "0.8rem",
            }}
          >
            {invoice._id}
          </p>

          <p className="text-muted fs-sm">
            Send a CashApp payment to{" "}
            <span className="text-info">{cashAppID}</span> by manually inputing
            the CashApp tag or scanning the QR code above. Set the amount to{" "}
            <span className="text-success">${amount}</span> and the note to{" "}
            <span className="text-warning">{url}</span>. When done, enter your
            Web Receipt URL or just the transaction ID from the URL below and
            click Process Payment!
          </p>

          <form className="w-100 text-center" onSubmit={verifyCashAppPayment}>
            <Form.Control
              type="text"
              id="checkurl"
              name="checkurl"
              placeholder="https://cash.app/payments/xxxxxxxxxxxxxxxxxxxxxxxxx/receipt"
              containerClass={"mb-3"}
            />

            <Button className="text-white mt-3" type="submit">
              "Process Payment"
            </Button>
          </form>
        </div>
      </Modal.Body>
    </>
  );
};
