import React from "react";

// All pages that rely on 3rd party components (other than Bootstrap) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Dashboards
import Dashboard from "./pages/dashboards";

// Address
import Address from "./pages/Address";

// DepositBalance
import DepositBalance from "./pages/DepositBalance";

// Orders
import Ordershistory from "./pages/Orders/OrdersHistory";
import CreateOrder from "./pages/Orders/CreateLabel";
import BulkOrders from "./pages/Orders/CSVOrders";

//support
import Support from "./pages/Support";

// Auth
import Page500 from "./pages/auth/Page500";
import Page404 from "./pages/auth/Page404";
import SignIn from "./pages/auth/SignIn";
import SignUp from "./pages/auth/SignUp";
import ResetPassword from "./pages/auth/ResetPassword";
import Verify from "./pages/auth/Verify";
import Confirm from "./pages/auth/ConfirmPassword";
import APIPage from "./pages/API";
import Referrals from "./pages/Referrals";
import Faqs from "./pages/FAQs";
import IntLabel from "./pages/Orders/International";
import IntOrderHistory from "./pages/Orders/IntHistory/index";

// Protected routes

const routes = [
  // {
  //   path: "/",
  //   element: <LandingLayout />,
  //   children: [
  //     {
  //       path: "",
  //       element: <Landing />,
  //     },
  //   ],
  // },
  {
    path: "/",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "address",
        element: <Address />,
      },
      {
        path: "orders",
        element: <Dashboard />,
      },
      {
        path: "create-label",
        element: <CreateOrder />,
      },
      {
        path: "create-int-label",
        element: <IntLabel />,
      },
      {
        path: "int-order-history",
        element: <IntOrderHistory />,
      },
      {
        path: "order-history",
        element: <Ordershistory />,
      },
      {
        path: "bulk-orders",
        element: <BulkOrders />,
      },
      {
        path: "depositBalance",
        element: <DepositBalance />,
      },
      {
        path: "support",
        element: <Support />,
      },
      {
        path: "api",
        element: <APIPage />,
      },
      {
        path: "referrals",
        element: <Referrals />,
      },
      {
        path: "faqs",
        element: <Faqs />,
      },
    ],
  },

  {
    path: "auth",
    element: <AuthLayout />,
    children: [
      {
        path: "sign-in",
        element: <SignIn />,
      },
      {
        path: "sign-up",
        element: <SignUp />,
      },
      {
        path: "reset-password",
        element: <ResetPassword />,
      },
      {
        path: "verify",
        element: <Verify />,
      },
      {
        path: "confirm",
        element: <Confirm />,
      },
      {
        path: "500",
        element: <Page500 />,
      },
    ],
  },

  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <SignIn />,
      },
    ],
  },
];

export default routes;
