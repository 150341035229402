import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { Alert, Button, Form, FormControl, InputGroup } from "react-bootstrap";

import useAuth from "../../hooks/useAuth";
import axios from "axios";
import Cookies from "js-cookie";
import { authConfig } from "../../config";
import { useRef } from "react";
import NotyfContext from "../../contexts/NotyfContext";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
function SignIn() {
  const navigate = useNavigate();
  const { signIn } = useAuth();
  const recaptchaRef = useRef(null);
  const notyf = useContext(NotyfContext);
  const [token, setToken] = useState(null);
  const [showPass, setShowPass] = useState(false);

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          password: "",
          submit: false,
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email("Must be a valid email")
            .max(255)
            .required("Email is required"),
          password: Yup.string().max(255).required("Password is required"),
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          // recaptchaRef.current.execute();
          console.log(token);
          try {
            const response = await axios.post(
              authConfig.server + "/api/v1/auth/login",
              {
                email: values.email,
                password: values.password,
                // captchaToken: token,
              },
            );
            const { accessToken, message, userData } = response.data;
            localStorage.setItem("userdata", JSON.stringify(userData));
            Cookies.set("token", accessToken);

            notyf.open({
              type: "success",
              message: message,
            });
            navigate("/");
          } catch (error) {
            if (error?.response?.status === 403) {
              localStorage.setItem("email", values.email);
              setTimeout(() => {
                navigate("/auth/verify");
              }, 1500);
            }

            notyf.open({
              type: "danger",
              message: error.response?.data?.message,
            });
            recaptchaRef.current.resetCaptcha();
            const message = error.message || "Something went wrong";

            setStatus({ success: false });
            setErrors({ submit: message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
        }) => (
          <Form onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert className="my-3" variant="danger">
                <div className="alert-message">{errors.submit}</div>
              </Alert>
            )}

            <Form.Group className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                size="lg"
                type="email"
                name="email"
                placeholder="Enter your email"
                value={values.email}
                isInvalid={Boolean(touched.email && errors.email)}
                onBlur={handleBlur}
                onChange={handleChange}
              />
              {!!touched.email && (
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <Form.Label>Password</Form.Label>
            <InputGroup className="mb-1">
              <FormControl
                size="lg"
                type={showPass ? "text" : "password"}
                name="password"
                placeholder="Enter your password"
                value={values.password}
                isInvalid={Boolean(touched.password && errors.password)}
                onBlur={handleBlur}
                onChange={handleChange}
              />

              <InputGroup.Text onClick={() => setShowPass(!showPass)}>
                {showPass ? (
                  <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </InputGroup.Text>
              {!!touched.password && (
                <Form.Control.Feedback type="invalid">
                  {errors.password}
                </Form.Control.Feedback>
              )}
            </InputGroup>
            <small className="d-flex justify-content-end mb-2">
              <Link to="/auth/reset-password">Forgot password?</Link>
            </small>

            {/* <div className="text-center ">
              <HCaptcha
                sitekey={authConfig.RECAPTCHA_KEY}
                onVerify={setToken}
                ref={recaptchaRef}
              />
            </div> */}

            <div className="text-center">
              <Button
                type="submit"
                variant="primary"
                size="lg"
                disabled={isSubmitting}
              >
                Sign in
              </Button>
            </div>

            <div className="text-center mt-3">
              Don't Have Account?
              <span style={{ marginLeft: "5px" }}>
                <Link to="/auth/sign-up">create</Link>
              </span>
            </div>
          </Form>
        )}
      </Formik>
      {/* <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={authConfig.RECAPTCHA_KEY}
        size="invisible"
      /> */}
    </>
  );
}

export default SignIn;
