import React, { useContext, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  FloatingLabel,
  Form,
  Table,
  Badge,
  Nav,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";
import axios from "axios";
// import { FormInput } from "../../../components";
// import Table from "../../../components/Table";
import Cookies from "js-cookie";
import { authConfig } from "../../../config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCross,
  faEye,
  faFileDownload,
  faLayerGroup,
  faRecycle,
  faStar,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import ViewDetails from "./ViewDetails";
import Pagination from "../../../components/Pagination";
import Loader from "../../../components/Loader";
import NotyfContext from "../../../contexts/NotyfContext";
import { faBan } from "@fortawesome/free-solid-svg-icons";
// import ViewDetails from "./ViewDetails";

const statusMap = {
  pre_transit: "Pre-transit",
  in_transit: "In-transit",
  out_for_delivery: "Out for delivery",
  delivered: "Delivered",
  return_to_sender: "Returned to Sender",
  failure: "Failed",
  unknown: (
    <>
      Label Created, <br />
      Awaiting Scan
    </>
  ),
  "N/A": "Unknown",
};

/* action column render */
const ActionColumn = ({ row }) => {
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [show, setShow] = useState(false);
  const onCloseModal = () => {
    setShow(false);
  };
  const onOpenModal = () => setShow(true);

  const deleteAddress = async (e) => {
    setDeleteLoading(true);

    await axios
      .delete(authConfig.server + "/api/v1/address/delete/" + row.values._id, {
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((res) => {
        setDeleteLoading(false);
      })
      .catch((err) => {
        setDeleteLoading(false);
      });
  };

  const downloadPdf = async () => {
    if (row.original)
      await axios
        .get(`${authConfig.server}/api/v1/order/download/${row.original._id}`, {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "label.pdf"); //or any other extension
          document.body.appendChild(link);
          link.click();
        })
        .catch((err) => {
          //   toast.error(err.response.data.message);
        });
  };

  return (
    <>
      <p className="action-icon" onClick={onOpenModal}>
        {" "}
        <i className="mdi mdi-eye text-primary"></i>
      </p>
      <p className="action-icon" onClick={downloadPdf}>
        {" "}
        <i className="mdi mdi-download-circle-outline text-warning"></i>
      </p>
      {/* <Link className="action-icon " onClick={deleteAddress}>
                {' '}
                <i className="mdi mdi-delete text-danger"></i>
            </Link> */}
    </>
  );
};

const OrderHistory = () => {
  const [loading, setLoading] = useState(false);
  const [singleOrder, setSingleOrder] = useState({});
  const [filter, setFilter] = useState("All");
  const [sort, setSort] = useState("asc");
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(20);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);
  const [url, setUrl] = useState("/api/v1/order/read");
  const [value, setValue] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [search, setSearch] = useState("");
  const notyf = useContext(NotyfContext);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loader, setLoader] = useState("");
  const [dateFilterApplied, setDateFilterApplied] = useState(false);
  const [refundMessage, setRefundMessage] = useState("");
  const [show, setShow] = useState(false);
  const onCloseModal = () => {
    setShow(false);
  };
  const onOpenModal = () => setShow(true);
  const [showDuplicate, setShowDuplicate] = useState(false);
  const onCloseModalDuplicate = () => {
    setShowDuplicate(false);
  };
  const onOpenModalDuplicate = () => setShowDuplicate(true);
  const [showReqest, setShowRequest] = useState(false);
  const onCloseRequestModal = () => {
    setShowRequest(false);
  };
  const onOpenRequestModal = () => setShowRequest(true);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const handleFilter = (val) => {
    setValue(val);
    setFilter(val);
    getOrders(val);
  };

  useEffect(() => {
    getOrders();
  }, [page, sort, filter, limit, search]);

  const getOrders = async (resetDate = false) => {
    setLoading(<Loader />);
    await axios
      .get(
        `${
          authConfig.server
        }/api/v1/order/read?status=${filter}&sort=${sort}&page=${page}&limit=${limit}&from=${
          resetDate ? "" : fromDate
        }&to=${resetDate ? "" : toDate}&search=${search}`,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      )
      .then((res) => {
        setOrders(res.data.orders);
        setTotalPages(res.data.totalPages);
        setTotal(res.data.total);
        console.log(res.data);
        setLoading("");
      })
      .catch((err) => {
        console.log(err);
        setLoading("");
      });
  };

  const downloadPdf = async (id, toname) => {
    await axios
      .get(`${authConfig.server}/api/v1/order/download/${id}`, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${toname}.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => {
        if (err.response) {
        }
      });
  };

  const duplicateOrder = async (id) => {
    setLoader(<Loader />);

    await axios
      .post(
        authConfig.server + "/api/v1/order/duplicate/" + id,
        {},
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      )
      .then((res) => {
        getOrders();
        notyf.open({
          type: "success",
          message: res?.data?.message,
        });

        setLoader("");
        onCloseModalDuplicate();
      })
      .catch((err) => {
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
        setLoader("");
      });
  };

  const downloadZipAll = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);
    const params = {
      orders: selectedOrders,
    };

    await axios
      .post(
        `${authConfig.server}/api/v1/order/bulk-download-selected`,
        params,
        {
          responseType: "blob",
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      )
      .then((response) => {
        //  download zip file
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.zip"); //or any other extension
        document.body.appendChild(link);
        link.click();
        setLoader("");
      })
      .catch((err) => {
        setLoader("");
        if (err.response) {
          notyf.open({
            type: "danger",
            message: err.response?.data?.message,
          });
        }
      });
  };

  const downloadPdfAll = async (e) => {
    // setLoader(<Loader />);
    const params = {
      orders: selectedOrders,
    };
    await axios
      .post(`${authConfig.server}/api/v1/order/bulk-download-pdf`, params, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.pdf"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // setLoader("");
      })
      .catch((err) => {
        // setLoader("");
        if (err.response) {
        }
      });
  };

  const downloadCsvAll = async (e) => {
    // setLoader(<Loader />);
    const params = {
      orders: selectedOrders,
    };
    await axios
      .post(`${authConfig.server}/api/v1/order/bulk-download-csv`, params, {
        responseType: "blob",
        headers: {
          Authorization: "Bearer " + Cookies.get("token"),
        },
      })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "label.csv"); //or any other extension
        document.body.appendChild(link);
        link.click();
        // setLoader("");
      })
      .catch((err) => {
        // setLoader("");
        if (err.response) {
        }
      });
  };

  // request refund
  const requestRefund = async (e) => {
    e.preventDefault();
    setLoader(<Loader />);

    const params = {
      message: refundMessage,
    };

    await axios
      .post(
        authConfig.server + "/api/v1/order/request-refund/" + singleOrder._id,
        params,
        {
          headers: {
            Authorization: "Bearer " + Cookies.get("token"),
          },
        },
      )
      .then((res) => {
        setLoader("");
        getOrders();
        notyf.open({
          type: "success",
          message: res?.data?.message,
        });
        setSingleOrder({});
        onCloseRequestModal();
      })
      .catch((err) => {
        setLoader("");
        notyf.open({
          type: "danger",
          message: err.response?.data?.message,
        });
      });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <div className="page-title-box">
            <div className="page-title-right"></div>
            <h4 className="nk-block-title page-title text-info">
              Orders History
            </h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <Card>
            <Card.Body>
              <Row className="mb-3">
                <Col lg={12}>
                  <Row className="d-flex gy-2 gx-2 align-items-center justify-content-between">
                    <Col xs="auto w-50">
                      <Form.Control
                        type="text"
                        name="search"
                        placeholder="Search by From-Name To-Name Tracking..."
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                    <Col xs="auto">
                      <select
                        name="sort"
                        id=""
                        className="form-control"
                        value={sort}
                        onChange={(e) => {
                          setSort(e.target.value);
                        }}
                      >
                        <option value="asc">Sort by Date Ascending</option>
                        <option value="des">Sort by Date Descending</option>
                      </select>
                    </Col>
                    <Col xs="auto">
                      <div className="d-flex  align-items-center gap-2">
                        <span className="text-info fs-sm">Orders Visible:</span>
                        <Form.Select
                          name="sort"
                          id=""
                          className="form-control w-auto"
                          value={limit}
                          onChange={(e) => {
                            setLimit(e.target.value);
                          }}
                        >
                          <option value="20">20</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </Form.Select>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <div className="d-flex justify-content-between">
                <Nav variant=" d-flex gap-2">
                  <Nav.Item className="border rounded">
                    <Nav.Link
                      eventKey="first"
                      onClick={(e) => {
                        handleFilter("All");
                      }}
                    >
                      All
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="border rounded">
                    <Nav.Link
                      eventKey="second"
                      onClick={(e) => {
                        handleFilter("paid");
                      }}
                    >
                      Paid
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="border rounded">
                    <Nav.Link
                      eventKey="third"
                      onClick={(e) => {
                        handleFilter("completed");
                      }}
                    >
                      Fulfilled
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="border rounded">
                    <Nav.Link
                      eventKey="third"
                      onClick={() => handleFilter("cancelled")}
                    >
                      Cancelled
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                {selectedOrders.length === 0 ? (
                  <div></div>
                ) : (
                  <Nav variant=" d-flex  gap-2">
                    <Nav.Item className="border rounded">
                      <Nav.Link
                        eventKey="third"
                        onClick={() => handleFilter("cancelled")}
                      >
                        Selected : {selectedOrders.length}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item className="border rounded ">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Download ZIP</Tooltip>}
                      >
                        <Nav.Link
                          eventKey="first"
                          onClick={downloadZipAll}
                          className="text-warning"
                        >
                          {loader !== "" ? (
                            loader
                          ) : (
                            <span>
                              ZIP <FontAwesomeIcon icon={faFileDownload} />
                            </span>
                          )}
                        </Nav.Link>
                      </OverlayTrigger>
                    </Nav.Item>
                    <Nav.Item className="border rounded">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Download PDF</Tooltip>}
                      >
                        <Nav.Link
                          eventKey="first"
                          onClick={downloadPdfAll}
                          className="text-danger"
                        >
                          <span>
                            PDF <FontAwesomeIcon icon={faFileDownload} />
                          </span>
                        </Nav.Link>
                      </OverlayTrigger>
                    </Nav.Item>
                    <Nav.Item className="border rounded">
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Download CSV</Tooltip>}
                      >
                        <Nav.Link
                          eventKey="first"
                          onClick={downloadCsvAll}
                          className="text-info"
                        >
                          <span>
                            CSV <FontAwesomeIcon icon={faFileDownload} />
                          </span>
                        </Nav.Link>
                      </OverlayTrigger>
                    </Nav.Item>
                  </Nav>
                )}

                <div className="d-flex flex-column flex-lg-row justify-content-end align-items-center gap-2">
                  <div>
                    <Form.Control
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                    />
                  </div>

                  <span style={{ flex: "none" }} className="fs-xs text-info">
                    To
                  </span>
                  <div>
                    <Form.Control
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                    />
                  </div>
                  {!dateFilterApplied && (
                    <Button
                      disabled={loading}
                      variant="primary"
                      onClick={() => {
                        setDateFilterApplied(true);
                        getOrders();
                      }}
                      className="w-auto px-3"
                    >
                      Apply
                    </Button>
                  )}

                  {dateFilterApplied && (
                    <Button
                      disabled={loading}
                      variant="warning"
                      onClick={() => {
                        setDateFilterApplied(false);
                        setFromDate("");
                        setToDate("");
                        getOrders(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faBan} />
                    </Button>
                  )}
                </div>
              </div>
              <div className="table-responsive" style={{ overflowY: "hidden" }}>
                <Table className="mb-0 mt-3" striped>
                  <thead className="table-dark">
                    <tr>
                      <th>#</th>
                      <th>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={selectedOrders.length === orders.length}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedOrders(
                                  orders.map((order) => order._id),
                                );
                              } else {
                                setSelectedOrders([]);
                              }
                            }}
                          />
                        </div>
                      </th>
                      <th>ID</th>
                      <th>Type</th>
                      <th>FromName</th>
                      <th>ToName</th>
                      <th>Tracking</th>
                      <th>Price</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Tracking Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading}
                    {orders
                      // .filter((order) => {
                      //   if (search === "") {
                      //     return order;
                      //   } else if (
                      //     order.ToName.toLowerCase().includes(
                      //       search.toLowerCase()
                      //     )
                      //   ) {
                      //     return order;
                      //   }
                      //   // else if (
                      //   //   order.ToName.toLowerCase().includes(
                      //   //     search.toLowerCase()
                      //   //   )
                      //   // ) {
                      //   //   return order;
                      //   // }

                      //   return null;
                      // })
                      .map((record, index) => {
                        return (
                          <tr key={index}>
                            <td> {index + 1}</td>
                            <td>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  checked={selectedOrders.includes(record._id)}
                                  onChange={(e) => {
                                    if (e.target.checked) {
                                      setSelectedOrders([
                                        ...selectedOrders,
                                        record._id,
                                      ]);
                                    } else {
                                      setSelectedOrders(
                                        selectedOrders.filter(
                                          (id) => id !== record._id,
                                        ),
                                      );
                                    }
                                  }}
                                  id="flexCheckDefault"
                                />
                              </div>
                            </td>
                            <td>{record._id}</td>
                            <td>{record.labelType?.name}</td>
                            <td>{record.FromName}</td>
                            <td>{record.ToName}</td>
                            <td>{record.tracking}</td>
                            <td>${Number(record.price).toFixed(2)}</td>
                            <td>
                              {new Date(record.createdAt).toLocaleDateString()}
                            </td>
                            <td>
                              <h5>
                                {record.status === "completed" ||
                                record.status === "paid" ||
                                record.status === "fulfilled" ||
                                record.status === "refundAccepted" ? (
                                  <Badge bg="success" pill>
                                    {" "}
                                    {record.status}
                                  </Badge>
                                ) : record.status === "cancelled" ||
                                  record.status === "refundRequested" ? (
                                  <Badge bg="danger" pill>
                                    {" "}
                                    {record.status}
                                  </Badge>
                                ) : record.status === "refundRejected" ? (
                                  <Badge bg="warning" pill>
                                    {record.status}
                                  </Badge>
                                ) : (
                                  <Badge bg="warning" pill>
                                    {" "}
                                    {record.status}
                                  </Badge>
                                )}
                              </h5>
                            </td>
                            <td>
                              {" "}
                              <Badge
                                bg={
                                  record.latest_status === "delivered"
                                    ? "success"
                                    : record.latest_status === "pre_transit" ||
                                        record.latest_status === "in_transit"
                                      ? "primary"
                                      : record.latest_status ===
                                          "out_for_delivery"
                                        ? "warning"
                                        : record.latest_status ===
                                            "refundAccepted"
                                          ? "success"
                                          : "danger"
                                }
                              >
                                {statusMap[record.latest_status] || "N/A"}
                              </Badge>
                            </td>
                            <td>
                              {record.tracking && (
                                <div className="d-flex gap-2 text-center align-center">
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>View Details</Tooltip>}
                                  >
                                    <Button
                                      variant="info"
                                      size="small"
                                      className="btn btn-sm"
                                      onClick={() => {
                                        setSingleOrder(record);
                                        onOpenModal();
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faEye} />
                                    </Button>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Duplicate Order</Tooltip>}
                                  >
                                    <Button
                                      variant="success"
                                      size="small"
                                      className="btn btn-sm"
                                      onClick={() => {
                                        setSingleOrder(record);
                                        onOpenModalDuplicate();
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faLayerGroup} />
                                    </Button>
                                  </OverlayTrigger>
                                  {/* {record.status !== "refundRejected" &&
                                  record.status !== "refundAccepted" && (
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip>Request Refund</Tooltip>
                                      }
                                    >
                                      <Button
                                        variant="danger"
                                        className="btn btn-sm"
                                        size="small"
                                        onClick={() => {
                                          setSingleOrder(record);
                                          onOpenRequestModal();
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRecycle} />
                                      </Button>
                                    </OverlayTrigger>
                                  )} */}
                                  <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip>Download PDF</Tooltip>}
                                  >
                                    <Button
                                      variant="warning"
                                      className="btn btn-sm"
                                      size="small"
                                      onClick={() =>
                                        downloadPdf(record._id, record.ToName)
                                      }
                                    >
                                      <FontAwesomeIcon icon={faFileDownload} />
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
                <Pagination
                  page={page}
                  setPage={setPage}
                  totalPages={totalPages}
                />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {show ? (
        <ViewDetails
          isOpen={show}
          onClose={onCloseModal}
          details={singleOrder}
        />
      ) : null}

      <Modal
        show={showReqest}
        onHide={onCloseRequestModal}
        backdrop="static"
        keyboard={false}
        size={"md"}
        centered
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h3 className="text-danger"> Request Refund </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 pb-4 pt-0">
          <form onSubmit={requestRefund}>
            <div className="form-group mb-3">
              <label htmlFor="order-id">Reason</label>
              <textarea
                type="text"
                className="form-control "
                placeholder="Please enter a reason for requesting refund"
                name="reason"
                id="reason"
                onChange={(e) => {
                  setRefundMessage(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="d-flex mt-2 justify-content-between ">
              <button
                type="button"
                className="btn btn-white me-3"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="submit" className="btn btn-danger">
                request
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* Duplicate Conformation  */}

      <Modal
        show={showDuplicate}
        onHide={onCloseModalDuplicate}
        backdrop="static"
        keyboard={false}
        size={"sm"}
        centered
      >
        <Modal.Header className="pb-2 px-4 border-bottom-0" closeButton>
          <Modal.Title id="modal-title">
            <h4 className="text-warning"> Duplicate Order Confirmation </h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-4 pb-4 pt-0">
          <div className="form-group mb-3">
            <p className="text-muted text-center mb-0">
              are you sure that you want to duplicate this order
            </p>
            <p className="text-black mt-0 text-center fw-bold">
              {singleOrder._id}
            </p>
          </div>
          <div className="d-flex mt-2 justify-content-between ">
            <button
              type="button"
              className="btn btn-secondary me-3"
              onClick={onCloseModalDuplicate}
            >
              Close
            </button>
            <button
              onClick={() => duplicateOrder(singleOrder._id)}
              className="btn btn-success"
            >
              {loader !== "" ? loader : "Duplicate"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default OrderHistory;
