import React from "react";

import { Badge, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Feed = () => {
  const data = localStorage.getItem("userdata");
  const UserData = JSON.parse(data);
  const navigate = useNavigate();
  return (
    <>
      <Card className="flex-fill w-100">
        <Card.Header>
          <Card.Title className="mb-0">Account Information</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-2">
              <p className="h4">ID :</p>
              <p className="h4 text-danger">{UserData?.ID}</p>
            </div>

            <div className="d-flex gap-2">
              <p className="h4">Email :</p>
              <p className="h4 text-info">{UserData?.email}</p>
            </div>

            <div className="d-flex gap-2">
              <p className="h4">Balance :</p>
              <p className="h4 text-success">
                ${Number(UserData?.balance).toFixed(2)}
              </p>
            </div>
            <div className="d-flex gap-2">
              <p className="h4">Custom Pricing :</p>
              <p className="h4 text-warning">
                {UserData?.customPricingEnabled ? "Enabled" : "Disabled"}
              </p>
            </div>

            <div className="d-flex gap-2">
              <p className="h4">Joined :</p>
              <p className="h4 text-secondary">
                {new Date(UserData?.createdAt).toDateString()}
              </p>
            </div>

            <div className="d-flex gap-2">
              <Button onClick={() => navigate("/auth/reset-password")}>
                Change Password
              </Button>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default Feed;
