import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import {
  Badge,
  Card,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
  Stack,
  Tab,
  Tabs,
} from "react-bootstrap";

const SingleOrder = () => {
  const [key, setKey] = useState("Success");
  return (
    <Card>
      <Card.Body>
        <h3 className=" text-info mb-2">Get Single Order Details</h3>

        <div className=" mb-3">
          <h4 className="d-flex gap-3 ">
            <Badge bg="success">GET</Badge>
            <Badge bg="secondary" className="mr-4">
              https://api.goship.gg/api/v2/order/readOrder/:ID
            </Badge>
          </h4>
          <h4 className="d-flex gap-2 ">
            <b>Headers:</b>
            <Badge bg="warning">x-api-key : "API KEY"</Badge>
          </h4>
        </div>
        <h5>Response</h5>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="Success" title="Success">
            <textarea
              disabled
              style={{ height: "20rem", fontFamily: "monospace" }}
              className="w-100 text-warning bg-dark rounded-2 p-3 mt-3"
              value={`{	
                       "message": "Order fetched successfully",
	                    "order": <Order Object>
                    }`}
            />
          </Tab>
          <Tab eventKey="Failure" title="Failure">
            <textarea
              disabled
              style={{ height: "20rem", fontFamily: "monospace" }}
              className="w-100 text-danger bg-dark rounded-2 p-3 mt-3"
              value={`
                 {
    "status": 400 || 401 || 404,
    "message": ""
}
                    
                    `}
            />
          </Tab>
        </Tabs>
      </Card.Body>
    </Card>
  );
};

export default SingleOrder;
